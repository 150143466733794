import { useState, useEffect, useCallback } from "react";
import Submit from "../../components/forms/Submit";
import { FcComboChart, FcPackage, FcOk } from "react-icons/fc";
import { ARRAY_REMOVE, ARRAY_UNION, firestore } from "../Firebase";

const PollStation = ({ type, data, userId, onComplete, checkInData }) => {
  const [togglePoll, setTogglePoll] = useState(true);
  const [isCompleted, setCompleted] = useState(false);

  const [prevResponse, setPreviousResponse] = useState(null);

  const [showError, setShowError] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState(
    type === "poll" ? null : [0, 0, 0, 0]
  );

  const findCompleted = useCallback(() => {
    let results =
      type === "poll" ? checkInData.pollResults : checkInData.quizResults;
    let pollResults = results.filter((checkIns) => checkIns.ref === data.id);
    if (pollResults.length > 0) {
      setCompleted(true);
      setTogglePoll(false);
      setPreviousResponse(pollResults[0]);
      setSelectedIndices(pollResults[0].response);
    }
  }, []);

  useEffect(() => {
    findCompleted();
  }, [userId, findCompleted]);

  const handlePoll = (index, item) => {
    if (type === "poll") {
      if (selectedIndices === null) {
        setShowError(true);
        return;
      }
    } else {
      const sum = selectedIndices.reduce(
        (partial_sum, a) => partial_sum + a,
        0
      );
      if (sum === 0) {
        setShowError(true);
        return;
      }
    }

    let parentRef = firestore.collection("polls").doc(data.id);
    let checkInRef = firestore.collection("checkIns").doc(checkInData.id);
    let field = type === "poll" ? "pollResults" : "quizResults";
    if (isCompleted) {
      ARRAY_REMOVE(parentRef, field, {
        uid: userId,
        type: type,
        response: prevResponse.response,
      });
      ARRAY_REMOVE(checkInRef, field, prevResponse);
    }

    ARRAY_UNION(parentRef, field, {
      uid: userId,
      type: type,
      response: selectedIndices,
    }).then((response) => {
      setCompleted(true);

      ARRAY_UNION(checkInRef, field, {
        ref: data.id,
        type: type,
        response: selectedIndices,
      });
    });
    setShowError(false);
    setTogglePoll(!togglePoll);

    onComplete(type);
  };

  return (
    <div className="tw-w-96 tw-container tw-p-2 tw-mx-auto tw-mt-3 tw-font-thin">
      <button
        onClick={() => setTogglePoll(!togglePoll)}
        className={
          "tw-flex tw-justify-between tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left " +
          (togglePoll === true ? "tw-rounded-t-md" : "tw-rounded-md")
        }
      >
        <div className="tw-text-4xl tw-text-gray-800 tw-font-thin tw-capitalize">
          {type === "poll" && (
            <FcComboChart className="tw-inline-block tw-align-text-top" />
          )}{" "}
          {type === "quiz" && (
            <FcPackage className="tw-inline-block tw-align-text-top" />
          )}
          {type}
        </div>
        <div className="tw-text-normal tw-text-gray-800 tw-font-thin tw-capitalize">
          {isCompleted && <FcOk />}
        </div>
      </button>
      {togglePoll && (
        <div className="tw-rounded-md tw-border-2  tw-p-2 tw-border-purple-100 tw-bg-white">
          <div className="tw-bg-white ">
            <h1 className="tw-text-center tw-text-3xl tw-font-thin tw-capitalize tw-mb-3">
              {data.name}
            </h1>
            <div className="tw-mt-4 tw-text-center tw-text-sm">
              {data.description}
            </div>
          </div>

          {data.options &&
            data.options.map((item, index) => {
              let border = "tw-border-t-4 tw-border-purple-100 ";
              if (type === "poll") {
                if (index === selectedIndices) {
                  border = "tw-border-t-8 tw-border-purple-600 ";
                }
              } else {
                if (selectedIndices[index] === 1) {
                  border = "tw-border-t-8 tw-border-purple-600 ";
                }
              }
              return (
                <button
                  className={
                    border +
                    "tw-bg-white tw-w-full tw-rounded-b tw-px-4 tw-py-3 tw-shadow-md tw-my-2"
                  }
                  role="alert"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    if (type === "poll") {
                      setSelectedIndices(index);
                    } else {
                      let tmpArray = [...selectedIndices];
                      if (selectedIndices[index] === 1) {
                        // remove
                        tmpArray[index] = 0;
                      } else {
                        tmpArray[index] = 1;
                      }
                      // console.log(tmpArray);
                      setSelectedIndices(tmpArray);
                    }
                  }}
                >
                  <div className="tw-flex">
                    <div>
                      <p className="tw-text-gray-700 tw-text-left tw-font-bold">
                        {item}
                      </p>
                    </div>
                  </div>
                </button>
              );
            })}
          {showError && (
            <p className="tw-text-red-500 tw-text-right tw-font-thin">
              You have to select at least 1 option
            </p>
          )}
          <Submit label={"Submit"} onSubmit={handlePoll} />
        </div>
      )}
    </div>
  );
};

export default PollStation;
