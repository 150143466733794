import React from "react";
import { Link } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { MdAssessment } from "react-icons/md";
import { GoTrashcan } from "react-icons/go";
import { AiOutlineFileText, AiOutlineEdit } from "react-icons/ai";
import { withRouter } from "react-router-dom";
import { BiCalendarPlus, BiChat, BiNotepad, BiPoll } from "react-icons/bi";
import { CgEnter } from "react-icons/cg";
import { GrLaunch } from "react-icons/gr";
import { toast } from "react-toastify";

import { firestore, MERGE_FIELDS } from "../Firebase";
import IconButton from "../../components/IconButton";
import DateTime from "../../components/DateTime";
import DeleteItem from "../../components/DeleteItem";
import EditItem from "../../components/EditItem";
import CreateWorkshop from "./CreateWorkshop";
import Avatar from "../../components/Avatar";
import Quiz from "../quiz/Quiz";
import RichText from "../../components/RichText";
import { format } from "date-fns";

class WorkshopList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirm: false,
      isEdit: false,
      editData: null,

      isEditPoll: false,
      pollId: null,
      pollData: null,

      isEditObjectives: false,
      notesId: null,
      notesData: null,
    };
    this.deleteWorkshop = this.deleteWorkshop.bind(this);
    this.schedule = this.schedule.bind(this);
    this.onEdit = this.onEdit.bind(this);

    this.onEditPoll = this.onEditPoll.bind(this);
    this.onCreatePoll = this.onCreatePoll.bind(this);
    this.onCompleteEditPoll = this.onCompleteEditPoll.bind(this);

    this.onCreateObjectives = this.onCreateObjectives.bind(this);
    this.onEditObjectives = this.onEditObjectives.bind(this);
    this.onCompleteEditObjectives = this.onCompleteEditObjectives.bind(this);
  }

  onEdit(editData) {
    this.setState({ ...this.state, isEdit: true, editData: editData });
  }

  onEditPoll(pollId) {
    // Load the data and keep it
    // Pass it to quiz
    const pollDocRef = firestore.collection("polls").doc(pollId);

    pollDocRef.onSnapshot((res) => {
      this.setState({
        ...this.state,
        isEditPoll: true,
        pollId: pollId,
        pollData: res.data(),
      });
    });
  }

  onCompleteEditPoll(pollId) {
    this.setState({
      ...this.state,
      isEditPoll: false,
      pollId: null,
      pollData: null,
    });
  }

  deleteWorkshop(workshopId) {
    this.setState({ showDeleteConfirm: !this.state.showDeleteConfirm });
    let workshopRef = firestore.collection("workshops");

    workshopRef
      .doc(workshopId)
      .delete()
      .then(() => {
        // TODO
        toast(`Workshop successfully deleted!`);
      })
      .catch((error) => {
        // TODO
        toast.error(`Error while deleting workshop: ${error.message}`);
      });
  }

  onCreatePoll(pollId, workshopId, type) {
    // Attach the poll to workshop
    // On click of poll to pop the poll
    // Repeat the same for quiz
    MERGE_FIELDS("workshops", workshopId, { [type]: pollId })
      .then(() => {
        this.props.history.goBack();
      })
      .catch((error) => {
        toast.error(
          `Error while inserting poll reference to workshop: 
          ${error.message}`
        );
      });
  }

  onCreateObjectives(notesId, workshopId, content) {
    MERGE_FIELDS("workshops", workshopId, { objective: notesId })
      .then(() => {})
      .catch((error) => {
        toast.error(
          `Error while inserting objectives reference to workshop:
          ${error.message}`
        );
      });
  }

  onEditObjectives(notesId) {
    // Load the data and keep it
    // Pass it to quiz
    const notesDocRef = firestore.collection("notes").doc(notesId);

    notesDocRef.onSnapshot((res) => {
      this.setState({
        ...this.state,
        isEditObjectives: true,
        notesId: notesId,
        notesData: res.data(),
      });
    });
  }

  onCompleteEditObjectives() {
    this.setState({
      ...this.state,
      isEditObjectives: false,
      notesId: null,
      notesData: null,
    });
  }

  schedule(workshopInfo) {
    let docRef = firestore.collection("workshops").doc(workshopInfo.id);

    docRef
      .update({
        date: workshopInfo.date,
        time: workshopInfo.time,
        timeZone: workshopInfo.timeZone,
        // notes: workshopInfo.notes,
      })
      .then((res) => {
        toast("Workshop scheduling successful!");
        this.props.history.goBack();
      })
      .catch((error) => {
        // TODO
        toast.error(
          `Error occurred while scheduling workshop: ${error.message}`
        );
      });
  }

  renderWorkshops(workshops, showToolbar) {
    return workshops.map((item) => {
      let isCheckedIn = false;
      if (item.checkIns) {
        let checkIns = item.checkIns.filter(
          (element) => element.uid === this.props.userId
        );
        isCheckedIn = checkIns.length > 0 ? true : false;
      }

      return (
        <div
          className="mt-2 tw-p-3 tw-bg-white tw-rounded-lg tw-tracking-wide tw-border tw-border-blue-100"
          key={item.id}
        >
          <div className="tw-flex">
            {item.date && (
              <div className="tw-pr-3">
                <DateTime
                  date={item.date}
                  time={item.time}
                  timeZone={item.timeZone}
                />
                <div className="tw-flex tw-items-center tw-text-xs tw-font-thin tw-capitalize ">
                  <span className="tw-flex-grow tw-text-blue-500">
                    {item.timeZone ? item.timeZone.label : " "}
                  </span>
                </div>
              </div>
            )}
            <div className="tw-w-full">
              <div className="tw-flex tw-flex-wrap tw-justify-between">
                <div>
                  <div className="tw-text-3xl tw-font-normal">{item.name}</div>
                  <div>
                    <span>Created on </span>
                    <span className="tw-ml-auto">
                      <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
                        {format(
                          new Date("Wed, 28 Jul 2021 18:15:39 GMT"),
                          "MMMM do, yyyy"
                        )}
                      </span>
                    </span>
                  </div>
                </div>
                <Avatar userId={item.createdBy} size={"xs"} />
              </div>

              <div className="tw-flex text-left tw-text-xl">
                {item.description}
              </div>
            </div>
            {this.props.launchEvent !== undefined && (
              <div className="tw-ml-10">
                {item.createdBy === this.props.userId ? (
                  <IconButton
                    size={"lg"}
                    label={isCheckedIn ? "Enter" : "Launch Event"}
                    title={
                      isCheckedIn
                        ? "Enter the Event Room"
                        : "Launch Event To Start the Event"
                    }
                    icon={<GrLaunch />}
                    highlight={false}
                    onClick={(e) => {
                      e.preventDefault();

                      this.props.launchEvent(
                        item.id,
                        item.createdBy,
                        isCheckedIn,
                        item.checkIns
                      );
                    }}
                  />
                ) : (
                  <IconButton
                    size={"lg"}
                    label={isCheckedIn ? "Enter" : "Check In"}
                    title={
                      isCheckedIn
                        ? "Enter the Event Room"
                        : "Event Launched By The Host, Check In To Participate"
                    }
                    icon={<CgEnter />}
                    highlight={false}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.launchEvent(
                        item.id,
                        null,
                        isCheckedIn,
                        item.checkIns
                      );
                    }}
                  />
                )}
              </div>
            )}
          </div>

          {this.props.userId === item.createdBy && (
            <div className="tw-flex tw-flex-wrap" key={item.id}>
              <IconButton
                icon={<AiOutlineEdit />}
                title={"Update Event Details"}
                onClick={(e) => {
                  e.preventDefault();
                  this.onEdit(item);
                }}
              />
              <IconButton
                icon={<FaUsers />}
                title={"Add Participants"}
                onClick={() =>
                  this.props.history.push({
                    pathname: `/participants/${item.id}`,
                  })
                }
              />
              <IconButton
                icon={<BiNotepad />}
                title={"Add Rich Content"}
                onClick={(e) => {
                  e.preventDefault();
                  if (!item.objective) {
                    this.props.history.push({
                      pathname: `/notes/${item.id}`,
                      state: this.props.userId,
                      reference: item.id,
                      size: "md",
                      onComplete: this.onCreateObjectives,
                    });
                  } else {
                    this.onEditObjectives(item.objective);
                  }
                }}
              />
              <IconButton
                icon={<BiPoll />}
                title={"Attach a Poll to the Event"}
                onClick={(e) => {
                  e.preventDefault();
                  if (!item.poll) {
                    this.props.history.push({
                      pathname: `/poll/${item.id}`,
                      state: this.props.userId,
                      reference: item.id,
                      type: "poll",
                      isEdit: false,
                      onComplete: this.onCreatePoll,
                    });
                  } else {
                    // Edit if uid === createdBy
                    // Watch poll results
                    this.onEditPoll(item.poll);
                    // this.setState({
                    //   ...this.state,
                    //   isEditPoll: true,
                    //   pollId: item.poll,
                    // });
                  }
                }}
              />
              <IconButton
                icon={<MdAssessment />}
                title={"Attach a Quiz to the Event"}
                onClick={(e) => {
                  e.preventDefault();

                  if (!item.quiz) {
                    this.props.history.push({
                      pathname: `/poll/${item.id}`,
                      state: this.props.userId,
                      reference: item.id,
                      type: "quiz",
                      isEdit: false,
                      onComplete: this.onCreatePoll,
                    });
                  } else {
                    this.onEditPoll(item.quiz);
                  }
                }}
              />
              <IconButton
                icon={<BiCalendarPlus />}
                title={"Attach a Poll to the Event"}
                onClick={(e) => {
                  this.props.history.push({
                    pathname: `/schedule-workshop/${item.id}`,
                    state: {
                      ...item,
                      displayName: this.props.displayName,
                      isEdit: item.time.length > 0 ? true : false,
                    },
                    schedule: this.schedule,
                  });
                }}
              />

              <IconButton
                icon={<BiChat />}
                title={"Attach a Conversation Table"}
                onClick={() => alert("To be done!!!")}
              />
              <IconButton
                icon={<AiOutlineFileText className="text-end" />}
                label={"Details"}
                title={"Navigate to Event Designer"}
                onClick={(e) =>
                  this.props.history.push({
                    pathname: `/workshop/${item.id}`,
                    state: {
                      ...item,
                      displayName: this.props.displayName,
                      userId: this.props.userId,
                      mode: this.props.mode,
                    },
                    schedule: this.schedule,
                  })
                }
              />
              <button
                className="btn btn-sm"
                title="Delete Workshop"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    ...this.state,
                    showDeleteConfirm: true,
                    deleteItemReference: item.id,
                  });
                }}
              >
                <GoTrashcan className="text-end" />
              </button>
            </div>
          )}
        </div>
      );
    });
  }

  // Action before attending the event
  preview(workshopId) {
    // Do not show the content of Q, P and Insights
    let path = `/workshop/${workshopId}/check-in/preview`;
    this.props.history.push(path);
  }

  // Action after attending the event
  review(workshopId, checkInId) {
    // Do not show the content of Q, P and Insights
    let path = `/virtual-venue/${workshopId}/check-in/${checkInId}`;
    this.props.history.push(path);
  }

  renderWorkshopsMinimal(workshops, preview, review) {
    return workshops.map((item) => {
      let checkIn = item.checkIns.filter(
        (checkIn) => checkIn.uid === this.props.userId
      );
      return (
        <div
          className="mt-2 tw-p-3 tw-bg-white tw-rounded-lg tw-tracking-wide tw-border tw-border-blue-100 tw-cursor-pointer"
          key={item.id}
        >
          <div>
            <div className="tw-flex">
              <div className="tw-bg-white">
                <Avatar userId={item.createdBy} size={"xs"} />
              </div>
              <div
                className="tw-px-5"
                onClick={(e) =>
                  this.props.history.push({
                    pathname: `/workshop/${item.id}`,
                    state: {
                      ...item,
                      displayName: this.props.displayName,
                      mode: this.props.mode,
                      userId: this.props.userId,
                    },
                    schedule: this.schedule,
                  })
                }
              >
                {item.date && (
                  <div className="tw-flex text-left tw-text-sm tw-font-semibold">
                    {item.date}, {item.time}
                    <div className="tw-flex tw-items-center tw-text-xs tw-font-thin tw-capitalize ">
                      <span className="tw-flex-grow tw-text-blue-500">
                        {item.timeZone ? item.timeZone.label : " "}
                      </span>
                    </div>
                  </div>
                )}
                <div>
                  <div className="text-left tw-text-lg tw-font-thin">
                    {item.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-place-content-end">
            {preview && (
              <Link to={`/virtual-venue/${item.id}/check-in/preview`}>
                <IconButton
                  title={"Preview Event"}
                  label={"preview"}
                  size={"xs"}
                />
              </Link>
            )}
            {review && checkIn.checkInId !== undefined && (
              <Link
                to={`/virtual-venue/${item.id}/check-in/${checkIn.checkInId}`}
              >
                <IconButton
                  title={"Review Event"}
                  label={"review"}
                  size={"xs"}
                />
              </Link>
            )}
            {review && checkIn.checkInId === null && (
              <Link to={`/virtual-venue/${item.id}/check-in/preview`}>
                <IconButton title={"View Event"} label={"View"} size={"xs"} />
              </Link>
            )}
          </div>
        </div>
      );
    });
  }

  render() {
    const { workshops, showToolbar } = this.props;
    let myWorkshops = null;
    if (showToolbar) {
      myWorkshops = this.renderWorkshops(workshops, showToolbar);
    } else {
      myWorkshops = this.renderWorkshopsMinimal(
        workshops,
        this.props.preview,
        this.props.review
      );
    }
    return (
      <div className="mt-4">
        {myWorkshops}
        {this.state.showDeleteConfirm && (
          <DeleteItem
            onDelete={this.deleteWorkshop}
            onCancel={(e) => {
              e.preventDefault();
              this.setState({
                ...this.state,
                showDeleteConfirm: false,
                deleteItemReference: null,
              });
            }}
            itemLabel={"workshop"}
            itemReference={this.state.deleteItemReference}
          />
        )}
        {this.state.isEdit && (
          <EditItem
            content={
              <CreateWorkshop
                // createdBy={createdBy}
                // workshopId={workshopId}
                onCreate={() => {
                  // console.log("Workshop updated");
                  this.setState({
                    ...this.setState,
                    isEdit: false,
                    editData: null,
                  });
                }}
                isEdit={this.state.isEdit}
                data={this.state.editData}
              />
            }
            onCancel={(e) => {
              e.preventDefault();
              this.setState({ ...this.state, isEdit: false, editData: null });
            }}
          />
        )}
        {this.state.isEditPoll && (
          <EditItem
            content={
              <Quiz
                // createdBy={createdBy}
                // workshopId={workshopId}
                onCreate={() => {
                  this.setState({
                    ...this.setState,
                    isEditPoll: false,
                    pollId: null,
                  });
                }}
                isEdit={this.state.isEditPoll}
                pollId={this.state.pollId}
                userId={this.props.userId}
                data={this.state.pollData}
                onComplete={this.onCompleteEditPoll}
              />
            }
            onCancel={(e) => {
              e.preventDefault();
              this.onCompleteEditPoll();
            }}
          />
        )}
        {this.state.isEditObjectives && (
          <EditItem
            size="lg"
            content={
              <RichText
                // createdBy={createdBy}
                isEdit={this.state.isEditObjectives}
                notesId={this.state.notesId}
                userId={this.props.userId}
                data={this.state.notesData}
                onComplete={this.onCompleteEditObjectives}
                size="lg"
              />
            }
            onCancel={(e) => {
              e.preventDefault();
              this.onCompleteEditObjectives();
            }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(WorkshopList);
