import { useState } from "react";
import { AiOutlineFileAdd } from "react-icons/ai";
import { toast } from "react-toastify";
import TextArea from "../../components/forms/TextArea";

import { firestore, MERGE_FIELDS } from "../Firebase";

const CreateWorkshop = ({ userId, onCreate, isEdit, data }) => {
  let [toggleForm, setToggleForm] = useState(isEdit ? true : false);

  let clearData = {
    createdBy: userId,
    name: "", // input
    privacy: "high", // Dropdown low/high
    description: "", // Text Area
  };

  if (isEdit) {
    clearData.name = data.name;
    clearData.description = data.description;
    clearData.privacy = data.privacy;
  }

  let [formData, setFormData] = useState(clearData);

  function formDataPublish(e) {
    e.preventDefault();
    setToggleForm(!toggleForm);
    const workshopInfo = {
      checkIns: [],
      createdBy: userId, //
      createdOn: "",
      description: formData.description, //
      isLaunched: false,
      name: formData.name, //
      objective: "",
      participants: [],
      poll: "",
      privacy: formData.privacy, // Dropdown low/high
      quiz: "",
      starUsers: [],
      topics: [],
    };

    let workshopsCollection = firestore.collection("workshops");

    let action = null;
    if (isEdit) {
      action = MERGE_FIELDS("workshops", data.id, {
        name: workshopInfo.name,
        privacy: workshopInfo.privacy,
        description: workshopInfo.description,
      });
    } else {
      action = workshopsCollection.add(workshopInfo);
    }
    action
      .then((results) => {
        // TODO
        toast(`Workshop ${isEdit ? "updated" : "created"}!!!`);
        setFormData(clearData);

        onCreate(userId);
      })
      .catch((error) => {
        // TODO
        // console.error("Error occurred while creating workshop", error);
        setFormData(clearData);
        setToggleForm(!toggleForm);
        toast.error(
          "Create workshop failed due to internal error!, ",
          error.message
        );
      });
  }

  return (
    <div className="tw-App tw-container tw-mx-auto tw-mt-3 tw-font-thin">
      <button
        onClick={() => setToggleForm(!toggleForm)}
        className={
          "tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left " +
          (toggleForm === true ? "tw-rounded-t-md" : "tw-rounded-md")
        }
      >
        <div className="tw-text-4xl tw-text-gray-800 tw-font-normal">
          <AiOutlineFileAdd className="tw-inline-block tw-align-text-top" />{" "}
          {isEdit ? "Edit" : "Create"} Workshop
        </div>
      </button>

      {toggleForm && (
        <div className="tw-bg-white tw-border-r-2 tw-border-b-2 tw-border-l-2 tw-border-light-blue-500 tw-rounded-b-md tw-pl-4 tw-pr-4 tw-pb-4">
          <form onSubmit={formDataPublish}>
            {/* <div className="tw-tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
              <label
                htmlFor="name"
                className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
              >
                name
              </label>
              <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => {
                    e.preventDefault();
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  value={formData.name}
                  className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                />
              </div>
            </div> */}
            <TextArea
              label={`name`}
              name={"name"}
              value={formData.name}
              onChange={(value) => {
                setFormData({ ...formData, name: value });
              }}
              maxLength={100}
            />

            <div className="tw-flex tw-flex-wrap">
              <div className="tw-w-1/3">
                <label
                  htmlFor="privacy"
                  className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
                >
                  Privacy
                </label>
                <div className="">
                  <input
                    type="text"
                    name="privacy"
                    id="privacy"
                    onChange={(e) => {
                      e.preventDefault();
                      setFormData({ ...formData, privacy: e.target.value });
                    }}
                    value={formData.privacy}
                    className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                  />
                </div>
              </div>
            </div>

            {/* <div className="tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
              <label
                htmlFor="description"
                className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:tw-mt-px tw-sm:tw-pt-2"
              >
                Description
              </label>
              <div className="tw-mt-1 tw-sm:tw-mt-0 tw-sm:tw-col-span-2">
                <textarea
                  type={"text"}
                  name="description"
                  id="description"
                  onChange={(e) => {
                    e.preventDefault();
                    setFormData({ ...formData, description: e.target.value });
                  }}
                  value={formData.description}
                  className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                />
              </div>
            </div> */}
            <TextArea
              label={`description`}
              name={"description"}
              value={formData.description}
              onChange={(value) => {
                setFormData({ ...formData, description: value });
              }}
              maxLength={200}
            />

            <div className="tw-pt-5">
              <div className="tw-flex tw-justify-end">
                <button
                  type="submit"
                  className="tw-bg-purple-200 tw- tw-ml-3 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white hover:tw-bg-purple-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-400"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CreateWorkshop;
