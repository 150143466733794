import { useState } from "react";
import { BiPoll } from "react-icons/bi";
import { toast } from "react-toastify";
import { firestore, MERGE_FIELDS, ARRAY_UNION } from "../Firebase";
import Input from "../../components/forms/Input";
import TextArea from "../../components/forms/TextArea";
import Submit from "../../components/forms/Submit";
import { useLocation } from "react-router";
import Toggle from "../../components/forms/Toggle";

const Quiz = ({ isEdit, pollId, userId, data, onComplete }) => {
  // Types: Poll, Single, Multi
  let [toggleForm, setToggleForm] = useState(isEdit ? true : false);

  const location = useLocation();

  let isPoll = false;
  let clearData = null;
  let type;

  if (isEdit !== true) {
    isEdit = location.isEdit;
    isPoll = location.type === "poll" ? true : false;
    userId = location.state;
    type = location.type;
    clearData = {
      createdBy: location.state,
      name: "",
      privacy: "",
      type: location.type,
      description: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer1: undefined,
      answer2: undefined,
      answer3: undefined,
      answer4: undefined,
    };
  }

  if (isEdit && isEdit === true) {
    type = data.type;
    isPoll = type === "poll" ? true : false;
    clearData = {
      createdBy: data.createdBy,
      name: data.name,
      privacy: data.privacy,
      type: data.type,
      description: data.description,
      option1: data.options[0],
      option2: data.options[1],
      option3: data.options[2],
      option4: data.options[3],
      answer1: data.answers[0] === 0 ? undefined : "checked",
      answer2: data.answers[1] === 0 ? undefined : "checked",
      answer3: data.answers[2] === 0 ? undefined : "checked",
      answer4: data.answers[3] === 0 ? undefined : "checked",
    };
  }

  let [formData, setFormData] = useState(clearData);

  const publishPoll = () => {
    let options = [
      formData.option1,
      formData.option2,
      formData.option3,
      formData.option4,
    ];

    let answers = [
      formData.answer1 !== undefined ? 1 : 0,
      formData.answer2 !== undefined ? 1 : 0,
      formData.answer3 !== undefined ? 1 : 0,
      formData.answer4 !== undefined ? 1 : 0,
    ];

    const pollInfo = {
      createdBy: clearData.createdBy,
      name: formData.name,
      privacy: formData.privacy,
      type: formData.type,
      description: formData.description,
      options: options,
      answers: answers,
    };

    // let answerCount = answers.reduce((a, b) => a + b);

    let pollsCollection = firestore.collection("polls");

    let action = null;
    let actionString = "created";
    if (isEdit) {
      action = MERGE_FIELDS("polls", pollId, pollInfo);
      actionString = "updated";
    } else {
      action = pollsCollection.add(pollInfo);
    }

    action
      .then((results) => {
        // TODO
        toast(`Poll details successfully ${actionString}!!!`);
        setFormData(clearData);
        setToggleForm(!toggleForm);

        if (isEdit) {
          onComplete(data.id);
        } else {
          let pollId = results.id;
          let userRef = firestore.collection("users").doc(pollInfo.createdBy);
          ARRAY_UNION(userRef, "polls", pollId)
            .then((res) => {
              // TODO
              location.onComplete(pollId, location.reference, location.type);
            })
            .catch((error) => {
              // TODO
              toast.error(
                `Error occurred while inserting Poll Id to User Doc: ${error.message}`
              );
            });
        }
      })
      .catch((error) => {
        // TODO
        toast.error(`Error occurred while creating Poll: ${error.message}`);
      });
  };

  return (
    <div className="tw-App tw-container tw-mx-auto tw-mt-3 tw-font-thin">
      <button
        onClick={() => setToggleForm(!toggleForm)}
        className={
          "tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left " +
          (toggleForm === true ? "tw-rounded-t-md" : "tw-rounded-md")
        }
      >
        <div className="tw-text-4xl tw-text-gray-800 tw-font-thin tw-capitalize">
          <BiPoll className="tw-inline-block tw-align-text-top" />{" "}
          {isEdit ? "Edit " : "Create "}
          {type}
        </div>
      </button>
      {toggleForm && (
        <div className="tw-pt-10 tw-bg-white tw-border-r-2 tw-border-b-2 tw-border-l-2 tw-border-light-blue-500 tw-rounded-b-md tw-pl-4 tw-pr-4 tw-pb-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              publishPoll();
            }}
          >
            <TextArea
              label={"Title of the poll"}
              name={"name"}
              value={formData.name}
              maxLength={100}
              onChange={(value) => {
                setFormData({ ...formData, name: value });
              }}
            />
            <TextArea
              label={"Description"}
              name={"description"}
              value={formData.description}
              maxLength={200}
              onChange={(value) => {
                setFormData({ ...formData, description: value });
              }}
            />
            <Input
              label={"Privacy"}
              name={"privacy"}
              value={formData.privacy}
              onChange={(value) => {
                // const itemName = e.target.name;
                setFormData({ ...formData, privacy: value });
              }}
            />
            <div className="tw-flex">
              <div className="tw-w-5/6">
                <TextArea
                  label={`Option 1`}
                  name={"option_1"}
                  value={formData.option1}
                  onChange={(value) => {
                    setFormData({ ...formData, option1: value });
                  }}
                  maxLength={100}
                />
              </div>
              {!isPoll && (
                <div className="tw-w-1/6 tw-pt-12">
                  {/* <Input
                    //label={`Answer 1`}
                    type={"checkbox"}
                    name={"answer_1"}
                    value={formData.answer1}
                    onChange={(value) => {
                      setFormData({ ...formData, answer1: value });
                    }}
                  /> */}
                  <Toggle label={"Mark Answer"} />
                </div>
              )}
            </div>

            <div className="tw-flex">
              <div className="tw-w-5/6">
                <TextArea
                  label={`Option 2`}
                  name={"option_2"}
                  value={formData.option2}
                  onChange={(value) => {
                    setFormData({ ...formData, option2: value });
                  }}
                  maxLength={100}
                />
              </div>
              {!isPoll && (
                <div className="tw-w-1/6  tw-pt-12">
                  {/* <Input
                    //label={`Answer 1`}
                    type={"checkbox"}
                    name={"answer_2"}
                    value={formData.answer2}
                    onChange={(value) => {
                      setFormData({ ...formData, answer2: value });
                    }}
                  /> */}
                  <Toggle label={"Mark Answer"} />
                </div>
              )}
            </div>

            <div className="tw-flex">
              <div className="tw-w-5/6 tw-pt-12">
                <TextArea
                  label={`Option 3`}
                  name={"option_3"}
                  value={formData.option3}
                  onChange={(value) => {
                    setFormData({ ...formData, option3: value });
                  }}
                  maxLength={100}
                />
              </div>
              {!isPoll && (
                <div className="tw-w-1/6 tw-pt-12">
                  {/* <Input
                    //label={`Answer 1`}
                    type={"checkbox"}
                    name={"answer_3"}
                    value={formData.answer3}
                    onChange={(value) => {
                      setFormData({ ...formData, answer3: value });
                    }}
                  /> */}
                  <Toggle label={"Mark Answer"} />
                </div>
              )}
            </div>

            <div className="tw-flex">
              <div className="tw-w-5/6">
                <TextArea
                  label={`Option 4`}
                  name={"option_4"}
                  value={formData.option4}
                  onChange={(value) => {
                    setFormData({ ...formData, option4: value });
                  }}
                  maxLength={100}
                />
              </div>
              {!isPoll && (
                <div className="tw-w-1/6">
                  {/* <Input
                    // label={`Answer 4`}
                    type={"checkbox"}
                    name={"answer4"}
                    value={formData.answer4}
                    onChange={(value) => {
                      setFormData({ ...formData, answer4: value });
                    }}
                  /> */}
                  <Toggle label={"Mark Answer"} />
                </div>
              )}
            </div>

            {userId === clearData.createdBy && <Submit label="Submit" />}
          </form>
        </div>
      )}
    </div>
  );
};

export default Quiz;
