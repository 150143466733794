import { useState } from "react";
import { toast } from "react-toastify";
import { BsFillPersonLinesFill } from "react-icons/bs";

import Submit from "../../components/forms/Submit";
import TextArea from "../../components/forms/TextArea";
import { MERGE_FIELDS } from "../Firebase";

const UpdateProfile = ({ userId, data, onComplete }) => {
  // Types: Poll, Single, Multi
  let [toggleForm, setToggleForm] = useState(true);

  let clearData = {
    displayName: data.displayName,
    title: data.title,
    description: data.description,
  };

  let [formData, setFormData] = useState(clearData);

  const publishProfile = () => {
    const profile = {
      displayName: formData.displayName,
      title: formData.title,
      description: formData.description,
    };

    MERGE_FIELDS("users", userId, profile)
      .then((results) => {
        // TODO
        toast(`User details successfully updated!!!`);
        setFormData(clearData);
        setToggleForm(!toggleForm);

        onComplete();
      })
      .catch((error) => {
        // TODO
        toast.error(`Error occurred while creating Poll: ${error.message}`);
      });
  };

  return (
    <div className="tw-App tw-container tw-mx-auto tw-mt-3 tw-font-thin">
      <button
        onClick={() => setToggleForm(!toggleForm)}
        className={
          "tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left " +
          (toggleForm === true ? "tw-rounded-t-md" : "tw-rounded-md")
        }
      >
        <div className="tw-text-4xl tw-text-gray-800 tw-font-thin tw-capitalize">
          <BsFillPersonLinesFill className="tw-inline-block tw-align-text-top" />{" "}
          Update Profile
        </div>
      </button>
      {toggleForm && (
        <div className="tw-pt-10 tw-bg-white tw-border-r-2 tw-border-b-2 tw-border-l-2 tw-border-light-blue-500 tw-rounded-b-md tw-pl-4 tw-pr-4 tw-pb-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              publishProfile();
            }}
          >
            <TextArea
              label={"Display Name"}
              name={"name"}
              value={formData.displayName}
              onChange={(value) => {
                setFormData({ ...formData, displayName: value });
              }}
              maxLength={15}
            />
            <TextArea
              label={"Title"}
              name={"title"}
              value={formData.title}
              onChange={(value) => {
                // const itemName = e.target.name;
                setFormData({ ...formData, title: value });
              }}
              maxLength={25}
            />
            <TextArea
              label={"Description"}
              name={"description"}
              value={formData.description}
              onChange={(value) => {
                setFormData({ ...formData, description: value });
              }}
              maxLength={100}
            />

            <Submit label="Submit" />
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateProfile;
