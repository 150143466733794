import React from "react";

class Welcome extends React.Component {
  render() {
    return (
      <div className="text-center tw-mt-20">
        <span className="text-secondary font-weight-bold pl-1">
          Welcome to Event Central, © Copyright{" "}
          <a
            href="https://www.gowrishankar.info"
            className="font-weight-bold text-primary pl-1"
          >
            www.gowrishankar.info
          </a>
        </span>{" "}
        2021, All Rights Reserved, Version 0.1.4, Released On 30th Sep, 2021
      </div>
    );
  }
}

export default Welcome;
