import RichText from "../../components/RichText";

const Notes = ({ user }) => {
  return (
    <div className="container">
      <div className="tw-mt-20">
        {user == null && (
          <div className="tw-m-20 tw-text-8xl tw-text-gray-500 tw-text-center tw-font-semibold">
            Notes
          </div>
        )}
        <RichText />
      </div>
    </div>
  );
};

export default Notes;
