import React from "react";
import firebase, { firestore } from "../Firebase";
import FormError from "../../components/FormError";
import { toast } from "react-toastify";

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState(
      {
        [itemName]: itemValue,
      },
      () => {
        if (this.state.password !== this.state.passwordConfirm) {
          this.setState({
            errorMessage: "Passwords do not match!",
          });
        } else {
          this.setState({
            errorMessage: null,
          });
        }
      }
    );
  }

  handleSubmit(e) {
    let registrationInfo = {
      displayName: this.state.displayName,
      email: this.state.email,
      password: this.state.password,
    };

    e.preventDefault();
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        registrationInfo.email,
        registrationInfo.password
      )
      .then((FBUser) => {
        // TODO
        FBUser.user
          .updateProfile({
            displayName: registrationInfo.displayName,
          })
          .then(() => {
            this.props.history.push({
              pathname: "/home",
            });
          });
        const userDoc = firestore.collection("users").doc(FBUser.user.uid);
        userDoc
          .set({
            reviews: [],
            description: "",
            displayName: registrationInfo.displayName,
            file: "",
            filePath: "",
            fileType: "",
            fullPath: "",
            invites: [],
            title: "",
            workshops: [],
            topics: [],
            notes: [],
            polls: [],
            email: registrationInfo.email,
          })
          .then((_) => {
            toast("User created!!!");
          });
      })
      .catch((error) => {
        // TODO
        if (error.message !== null) {
          this.setState({
            errorMessage: error.message,
          });
        } else {
          this.setState({
            errorMessage: null,
          });
        }
      });
  }
  render() {
    return (
      <form className="mt-3" onSubmit={this.handleSubmit}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card bg-light">
                <div className="card-body">
                  <h3 className="font-weight-light mb-3">Register</h3>
                  <div className="form-row">
                    {this.state.errorMessage && (
                      <FormError theMessage={this.state.errorMessage} />
                    )}
                    <section className="col-sm-12 form-group">
                      <label
                        className="form-control-label sr-only"
                        htmlFor="displayName"
                      >
                        Display Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="displayName"
                        placeholder="Display Name"
                        name="displayName"
                        required
                        value={this.state.displayName}
                        onChange={this.handleChange}
                      />
                    </section>
                  </div>
                  <section className="form-group mt-2">
                    <label
                      className="form-control-label sr-only"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      placeholder="Email Address"
                      required
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </section>
                  <div className="form-row mt-4">
                    <section className="form-group mt-2">
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                    </section>
                    <section className="form-group mt-2">
                      <input
                        className="form-control"
                        type="password"
                        required
                        name="passwordConfirm"
                        placeholder="Repeat Password"
                        value={this.state.passwordConfirm}
                        onChange={this.handleChange}
                      />
                    </section>
                  </div>
                  <div className="form-group text-end mt-2 mb-0">
                    <button className="btn btn-primary" type="submit">
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Register;
