import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import WorkshopList from "./WorkshopList";
import CreateWorkshop from "./CreateWorkshop";
import { firestore, GET_DOC_BY_IDS } from "../Firebase";

const Workshops = ({ user }) => {
  let { userId } = useParams();

  const [workshops, setWorkshops] = useState([]);
  const [workshopCount, setWorkshopCount] = useState(0);

  const [reviews, setReviews] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  // const [reviewers, setReviewers] = useState([]);

  const [invites, setInvites] = useState([]);
  const [inviteCount, setInviteCount] = useState(0);
  // const [inviters, setInviters] = useState([]);

  const fetchWorkshops = useCallback((userId) => {
    let workshopsRef = firestore.collection("workshops");
    workshopsRef
      .where("createdBy", "==", userId)
      .onSnapshot((workshopsSnapshot) => {
        let workshops = [];
        workshopsSnapshot.forEach((doc) => {
          workshops.push({ ...doc.data(), id: doc.id });
        });
        setWorkshopCount(workshops.length);
        setWorkshops(workshops);
      });
  }, []);

  const fetchReviews = useCallback((userId) => {
    let userDoc = firestore.collection("users").doc(userId);
    userDoc.onSnapshot((userSnapshot) => {
      let workshopIds = userSnapshot.data().reviews;
      if (workshopIds.length > 0) {
        GET_DOC_BY_IDS("workshops", workshopIds)
          //.get({ name: true, date: true, time: true, uid: true })
          .onSnapshot((res) => {
            let workshops = [];
            let reviewers = [];
            res.forEach((doc) => {
              let data = doc.data();
              workshops.push({ ...data, id: doc.id });
              reviewers.push(data.createdBy);
            });
            setReviewCount(workshopIds.length);
            setReviews(workshops);
            // setReviewers(reviewers);
          });
      }
    });
  }, []);

  const fetchInvites = useCallback((userId) => {
    let userDoc = firestore.collection("users").doc(userId);
    userDoc.onSnapshot((userSnapshot) => {
      let workshopIds = userSnapshot.data().invites;
      if (workshopIds.length > 0) {
        GET_DOC_BY_IDS("workshops", workshopIds)
          //.get({ name: true, date: true, time: true, uid: true })
          .onSnapshot((res) => {
            let workshops = [];
            let inviters = [];
            res.forEach((doc) => {
              let data = doc.data();
              workshops.push({ ...data, id: doc.id });
              inviters.push(data.createdBy);
            });
            setInviteCount(workshopIds.length);
            setInvites(workshops);
            // setInviters(inviters);
          });
      }
    });
  }, []);

  useEffect(() => {
    fetchWorkshops(userId);
  }, [userId, fetchWorkshops]);

  useEffect(() => {
    if (userId) fetchReviews(userId);
  }, [userId, fetchReviews]);

  useEffect(() => {
    if (userId) fetchInvites(userId);
  }, [userId, fetchInvites]);

  const onCreateWorkshop = () => {
    fetchWorkshops(userId);
  };

  return (
    <div className="tw-w-full tw-h-screen">
      <div className="tw-flex tw-flex-col lg:tw-grid lg:tw-gap-4 2xl:tw-gap-6 lg:tw-grid-cols-4 2xl:tw-row-span-2 2xl:tw-pb-8 tw-ml-2 tw-pt-4 tw-px-6">
        <div className="tw-bg-blue-100 tw-bg-opacity-30 lg:tw-order-1 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-2 tw-rounded-lg tw-shadow-xl tw-mb-5 lg:tw-mb-0">
          <div className="tw-m-4 tw-border-0 tw-rounded-2">
            {workshops && workshops.length ? (
              <h4 className="tw-text-5xl font-weight-light">
                My Workshops ({workshopCount})
              </h4>
            ) : null}
            {workshops && (
              <div className="list-group list-group-flush">
                <WorkshopList
                  workshops={workshops}
                  displayName={user}
                  showToolbar={true}
                  userId={userId}
                  mode={"write"}
                />
              </div>
            )}
          </div>
        </div>
        <div className="tw-bg-blue-100 tw-bg-opacity-30 lg:tw-order-2 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-1 tw-rounded-lg tw-shadow-xl tw-pb-4 tw-mb-5 lg:tw-mb-0">
          <div className="tw-m-4 tw-border-0 tw-rounded-2">
            {reviews && reviews.length ? (
              <h4 className="tw-text-5xl font-weight-light m-0">
                My Reviews ({reviewCount})
              </h4>
            ) : null}
            {reviews && (
              <div className="list-group list-group-flush">
                <WorkshopList
                  workshops={reviews}
                  showToolbar={false}
                  mode={"read"}
                />
              </div>
            )}
          </div>
        </div>

        <div className="tw-bg-blue-100 tw-bg-opacity-30 lg:tw-order-2 lg:tw-row-span-4 lg:tw-col-span-1 tw-rounded-lg tw-shadow-xl tw-mb-5 lg:tw-pb-4">
          {/* <Link to={`/user/${userId}`}>
            <div className="tw-bg-white tw-m-4 tw-p-2 tw-rounded-lg tw-shadow-xl">
              <Avatar isOnline={false} userId={userId} />
            </div>
          </Link> */}
          <div className="tw-m-4 tw-border-0 tw-rounded-2">
            <CreateWorkshop userId={userId} onCreate={onCreateWorkshop} />
          </div>
          <div className="tw-m-4 tw-border-0 tw-rounded-2">
            {invites && invites.length ? (
              <h4 className="tw-text-5xl font-weight-light tw-mt-8">
                My Invites ({inviteCount})
              </h4>
            ) : null}
            {invites && (
              <div className="list-group list-group-flush">
                <WorkshopList
                  workshops={invites}
                  showToolbar={false}
                  mode={"read"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workshops;
