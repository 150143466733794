import { Link } from "react-router-dom";
import { FcBusinessman, FcBusinesswoman } from "react-icons/fc";
import Avatar from "../../components/Avatar";

const FriendsCard = ({ label, friends }) => {
  return (
    <div className="tw-bg-white tw-p-3 hover:tw-shadow">
      <div className="tw-flex tw-items-center tw-space-x-3 tw-font-semibold tw-text-gray-900 text-xl tw-leading-8">
        <span className="tw-text-green-500">
          <FcBusinesswoman />
          <FcBusinessman />
        </span>
        <span>{label}</span>
      </div>

      <div className="tw-flex tw-flex-wrap">
        {friends &&
          friends.map((item) => {
            return (
              <div className="tw-px-5" key={item.uid}>
                <Avatar userId={item.uid} size={"xs"} />
              </div>
            );
          })}
      </div>

      {!friends && (
        <div className="tw-grid tw-grid-cols-3">
          <div className="tw-text-center tw-my-2">
            <img
              className="tw-h-16 tw-w-16 tw-rounded-full tw-mx-auto"
              src="https://cdn.australianageingagenda.com.au/wp-content/uploads/2015/06/28085920/Phil-Beckett-2-e1435107243361.jpg"
              alt=""
            />
            <Link to="#" className="tw-text-main-color">
              Kojstantin
            </Link>
          </div>
          <div className="tw-text-center tw-my-2">
            <img
              className="tw-h-16 tw-w-16 tw-rounded-full tw-mx-auto"
              src="https://firebasestorage.googleapis.com/v0/b/network-rail-safety.appspot.com/o/profile-images%2F1631703841129_profile.png?alt=media&token=d52f8a69-e4ee-4f89-a469-85ed1dbb8472"
              alt=""
            />
            <Link to="#" className="tw-text-main-color">
              Shanky
            </Link>
          </div>
          <div className="tw-text-center tw-my-2">
            <img
              className="tw-h-16 tw-w-16 tw-rounded-full tw-mx-auto"
              src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
              alt=""
            />
            <Link to="#" className="tw-text-main-color">
              Natie
            </Link>
          </div>
          <div className="tw-text-center tw-my-2">
            <img
              className="tw-h-16 tw-w-16 tw-rounded-full tw-mx-auto"
              src="https://bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com/public/images/f04b52da-12f2-449f-b90c-5e4d5e2b1469_361x361.png"
              alt=""
            />
            <Link to="#" className="tw-text-main-color">
              Casey
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default FriendsCard;
