import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { BiCalendarPlus } from "react-icons/bi";

import Participants from "./Participants";
import Topics from "./topics/Topics";
import DateTime from "../../components/DateTime";
import Avatar from "../../components/Avatar";
import IconButton from "../../components/IconButton";
import { Link } from "react-router-dom";
import EditItem from "../../components/EditItem";
import AddAppointment from "../appointments/AddAppointment";

const Workshop = () => {
  let location = useLocation();
  let history = useHistory();
  let [isUpdateSchedule, updateSchedule] = useState(false);

  let dateTime = (date, time, timeZone) => {
    if (date) {
      return (
        <>
          <button
            onClick={(e) => {
              e.preventDefault();
              updateSchedule(true);
            }}
          >
            <div className="tw-pr-3">
              <DateTime
                date={date}
                time={time}
                timeZone={timeZone}
                addAppointment={<BiCalendarPlus />}
              />
            </div>
          </button>
          {isUpdateSchedule && (
            <EditItem
              onCancel={(e) => {
                e.preventDefault();
                updateSchedule(false);
              }}
              content={
                <AddAppointment
                  isEdit={true}
                  schedule={location.schedule}
                  onComplete={(appointmentInfo) => {
                    location.state.date = appointmentInfo.date;
                    location.state.time = appointmentInfo.time;
                    location.state.timeZone = appointmentInfo.timeZone;
                  }}
                  mode={location.state.mode}
                />
              }
            />
          )}
        </>
      );
    } else {
      return (
        <Link
          to={(location) => ({
            ...location,
            pathname: `/schedule-workshop/${location.state.id}`,
            schedule: location.schedule,
            onComplete: (appointmentInfo) => {
              location.state.date = appointmentInfo.date;
              location.state.time = appointmentInfo.time;
              location.state.timeZone = appointmentInfo.timeZone;

              history.push({
                pathname: `/workshop/${location.state.id}`,
                state: location.state,
              });
            },
          })}
        >
          <IconButton
            icon={<BiCalendarPlus />}
            title={"Schedule Workshop"}
            label={"Schedule Workshop"}
            size={"lg"}
          />
        </Link>
      );
    }
  };

  return (
    <div className="tw-w-full tw-h-screen">
      <div className="tw-bg-white tw-m-8 tw-p-8 lg:tw-order-1 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-2 tw-rounded-lg tw-shadow-xl tw-mb-5 lg:tw-mb-0">
        <h1 className="tw-text-center tw-text-7xl tw-font-thin tw-capitalize tw-mb-3">
          {location.state.name}
        </h1>
        <div className="tw-mt-4 tw-text-center tw-text-2xl tw-font-thin ">
          {location.state.description}
        </div>
        <div className="tw-flex tw-mt-4 tw-justify-between tw-px-3">
          {dateTime(
            location.state.date,
            location.state.time,
            location.state.timeZone
          )}
          <Avatar isOnline={false} userId={location.state.userId} />
        </div>

        <div className="tw-mx-3">
          <div className="tw-flex tw-items-center tw-text-xl tw-font-thin tw-capitalize ">
            <span className="tw-flex-grow tw-text-blue-500">
              {location.state.timeZone ? location.state.timeZone.label : " "}
            </span>
            <span className="tw-text-right tw-text-blue-500">
              {/* {location.state.displayName} */}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-text-xs tw-uppercase tw-font-semibold">
            When
            <span className="tw-flex-grow tw-text-right ">Organizer</span>
          </div>
        </div>
      </div>
      <div className="tw-bg-white tw-m-8 tw-p-8 lg:tw-order-1 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-2 tw-rounded-lg tw-shadow-xl tw-mb-5 lg:tw-mb-0">
        <div className="tw-leading-tight tw-text-xl">
          {location.state.notes}
        </div>
        <div className="tw-flex tw-items-center tw-text-xs tw-uppercase tw-font-semibold">
          Objectives
        </div>
      </div>
      <div className="tw-flex tw-flex-col lg:tw-grid lg:tw-gap-4 2xl:tw-gap-6 lg:tw-grid-cols-3 2xl:tw-row-span-2 2xl:tw-pb-8 tw-ml-2 tw-pt-4 tw-px-1">
        <div className=" tw-m-6 tw-bg-blue-100 tw-bg-opacity-30 lg:tw-order-1 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-2 tw-rounded-lg tw-shadow-xl tw-pb-5">
          <div className="tw-pt-4 tw-border-0 tw-rounded-2">
            <Topics
              createdBy={location.state.createdBy}
              workshopId={location.state.id}
              topicIds={location.state.topics}
              mode={location.state.mode}
              userId={location.state.userId}
            />
          </div>
        </div>
        <div className="tw-m-6 tw-bg-blue-100 tw-bg-opacity-30 lg:tw-order-2 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-1 tw-rounded-lg tw-shadow-xl tw-pb-5">
          <div className="tw-pt-4 tw-border-0 tw-rounded-2">
            <Participants mode={location.state.mode} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workshop;
