import React from "react";
import { GoTrashcan, GoStar, GoMail } from "react-icons/go";
import { toast } from "react-toastify";
import Avatar from "../../components/Avatar";
import IconButton from "../../components/IconButton";
import DeleteItem from "../../components/DeleteItem";
import { firestore, ARRAY_REMOVE, ARRAY_UNION } from "../Firebase";

class ParticipantsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteItemReference: null,
      deleteConfirm: false,
      deleteItemUId: null,
    };

    this.participantObj = {};
    this.props.participants.forEach((item) => {
      this.participantObj[item.email] = item;
    });

    this.deleteParticipant = this.deleteParticipant.bind(this);
    this.toggleStar = this.toggleStar.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  confirmDelete = (e) => {
    e.preventDefault();
    this.setState({ deleteConfirm: !this.state.deleteConfirm });
  };

  deleteParticipant = (itemReference) => {
    let workshopRef = firestore
      .collection("workshops")
      .doc(this.props.workshopId);

    // let reference = {
    //   displayName: this.participantObj[itemReference].displayName,
    //   email: this.participantObj[itemReference].email,
    //   uid: this.participantObj[itemReference].uid,
    // };

    ARRAY_REMOVE(
      workshopRef,
      "participants",
      this.participantObj[itemReference]
    )
      .then(() => {
        // TODO
        // console.log("Paticipant deleted");
        this.setState({ deleteConfirm: !this.state.deleteConfirm });
        toast(`Participant successfully removed!`);
        if (this.state.deleteItemUId.length > 0) {
          ARRAY_REMOVE(
            firestore.collection("users").doc(this.state.deleteItemUId),
            "invites",
            this.props.workshopId
          );
        }
      })
      .catch((error) => {
        // TODO
        // console.error("Error while deleting the participant: ", error);
        toast.error(`Error while deleting the participant, ${error.message}`);
      });
  };

  toggleStar = (e, isStar, email, name, uid) => {
    e.preventDefault();
    let workshopRef = firestore
      .collection("workshops")
      .doc(this.props.workshopId);

    let usersRef = firestore.collection("users").doc(uid);

    let starOpsPromise = null;

    if (isStar) {
      starOpsPromise = ARRAY_REMOVE(workshopRef, "starUsers", { email: email });
    } else {
      starOpsPromise = ARRAY_UNION(workshopRef, "starUsers", { email: email });
    }

    starOpsPromise
      .then((results) => {
        let reviewOpsPromise = null;
        if (isStar) {
          reviewOpsPromise = ARRAY_REMOVE(
            usersRef,
            "reviews",
            this.props.workshopId
          );
        } else {
          reviewOpsPromise = ARRAY_UNION(
            usersRef,
            "reviews",
            this.props.workshopId
          );
        }
        reviewOpsPromise
          .then((results) => {
            toast(
              `Review request ${isStar ? "revoked from" : "sent to"} ${name}!!!`
            );
          })
          .catch((error) => {
            // TODO
            console.error("Error", error);
            toast.error(`Review request failed: ${error.message}`);
          });
      })
      .catch((error) => {
        // TODO
        console.error("Error", error);
        toast.error(`Star set failed: ${error.message}`);
      });
  };

  render() {
    const isAdmin = this.props.createdBy === this.props.userID ? true : false;
    const participants = this.props.participants;
    const myParticipants = participants.map((item) => {
      return (
        <div
          className="tw-mt-2 tw-p-4 tw-bg-white tw-rounded-lg tw-tracking-wide tw-border tw-border-blue-100"
          key={item.email}
        >
          <Avatar
            isOnline={false}
            name={item.displayName}
            title={item.email}
            userId={item.uid}
            size={"sm"}
          />
          <div className=" ">
            <div>
              {isAdmin && (
                <div className="tw-flex">
                  {item.uid.length > 0 && (
                    <IconButton
                      icon={<GoStar />}
                      title={
                        this.toggleStar
                          ? "Revoke Review Rights"
                          : "Mark as a Reviewer"
                      }
                      onClick={(e) =>
                        this.toggleStar(
                          e,
                          this.props.starUsers.includes(item.email),
                          item.email,
                          item.displayName,
                          item.uid
                        )
                      }
                      highlight={this.props.starUsers.includes(item.email)}
                    />
                  )}
                  {item.uid.length === 0 && (
                    <IconButton
                      icon={
                        <a
                          href={`mailto:${item.email}`}
                          title="Mail to participant"
                        >
                          <GoMail />
                        </a>
                      }
                      title={"Mail to participant"}
                      onClick={(e) =>
                        this.toggleStar(
                          e,
                          this.props.starUsers.includes(item.email),
                          item.email,
                          item.displayName
                        )
                      }
                    />
                  )}
                  {this.props.mode !== "read" && (
                    <button
                      className="btn btn-sm"
                      title="Delete Participant"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          deleteItemReference: item.email,
                          deleteConfirm: !this.state.deleteConfirm,
                          deleteItemUId: item.uid,
                        });
                      }}
                    >
                      <GoTrashcan />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
    return (
      <>
        {myParticipants}
        {this.state.deleteConfirm && (
          <DeleteItem
            onDelete={this.deleteParticipant}
            onCancel={this.confirmDelete}
            itemLabel={"participant"}
            itemReference={this.state.deleteItemReference}
          />
        )}
      </>
    );
  }
}

export default ParticipantsList;
