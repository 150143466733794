import { useState } from "react";
import { AiOutlineFileAdd } from "react-icons/ai";
import { toast } from "react-toastify";
import { firestore, ARRAY_UNION, MERGE_FIELDS } from "../../Firebase";
import TextArea from "../../../components/forms/TextArea";

const AddTopic = ({
  createdBy,
  workshopId,
  onComplete,
  isEdit,
  data,
  mode,
}) => {
  let [toggleForm, setToggleForm] = useState(isEdit ? true : false);

  let clearData = {
    createdBy: createdBy,
    title: "", // input
    privacy: "high", // Dropdown low/high
    description: "", // Text Area
    insights: "", // Text Area
  };

  if (isEdit && isEdit === true) {
    clearData.title = data.title;
    clearData.privacy = data.privacy;
    clearData.description = data.description;
    clearData.insights = data.insights;
  }
  let [formData, setFormData] = useState(clearData);

  function formDataPublish(e) {
    e.preventDefault();
    setToggleForm(!toggleForm);

    const topicInfo = {
      createdBy: createdBy,
      title: formData.title, // input
      privacy: formData.privacy, // Dropdown low/high
      description: formData.description, // Text Area
      fileType: "", // Hidden Input
      file: "", // File,
      poll: "",
      quiz: "",
      insights: formData.insights, // Text Area
    };

    let topicCollection = firestore.collection("topics");

    let action = topicCollection.add(topicInfo);
    let actionString = "created";
    if (isEdit) {
      action = MERGE_FIELDS("topics", data.id, topicInfo);
      // action = topicCollection.set(topicInfo, { merge: true });
      actionString = "updated";
    }

    action
      .then((results) => {
        // TODO
        toast(`Topic successfully ${actionString}!!!`);
        setFormData(clearData);

        if (isEdit) {
          onComplete(data.id);
        } else {
          let topicId = results.id;
          let workshopRef = firestore.collection("workshops").doc(workshopId);
          ARRAY_UNION(workshopRef, "topics", topicId)
            .then((res) => {
              // TODO
              onComplete(topicId);
            })
            .catch((error) => {
              // TODO
              toast.error(
                `Error occured while inserting Topic Id to workshop: ${error.message}`
              );
            });
        }
      })
      .catch((error) => {
        // TODO
        // console.error("Error occurred while creating Topic", error);
        toast.error(`Error occurred while creating Topic: ${error.message}`);
      });
  }

  return (
    <div className="tw-App tw-container tw-mx-auto tw-mt-3 tw-font-thin">
      <button
        onClick={() => setToggleForm(!toggleForm)}
        className={
          "tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left " +
          (toggleForm === true ? "tw-rounded-t-md" : "tw-rounded-md")
        }
      >
        <div className="tw-text-4xl tw-text-gray-800 tw-font-thin">
          <AiOutlineFileAdd className="tw-inline-block tw-align-text-top" />{" "}
          {isEdit ? "Edit " : "Add "}
          Topic
        </div>
      </button>

      {toggleForm && (
        <div className="tw-bg-white tw-border-r-2 tw-border-b-2 tw-border-l-2 tw-border-light-blue-500 tw-rounded-b-md tw-pl-4 tw-pr-4 tw-pb-4">
          <form onSubmit={formDataPublish}>
            {/* <div className="tw-tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
              <label
                htmlFor="title"
                className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
              >
                Title
              </label>
              <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
                <input
                  type="text"
                  name="title"
                  id="title"
                  onChange={(e) => {
                    e.preventDefault();
                    setFormData({ ...formData, title: e.target.value });
                  }}
                  value={formData.title}
                  className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                />
              </div>
            </div> */}
            <TextArea
              label={`title`}
              name={"title"}
              value={formData.title}
              onChange={(value) => {
                setFormData({ ...formData, title: value });
              }}
              maxLength={100}
            />

            <div className="tw-flex tw-flex-wrap">
              <div className="tw-w-1/3">
                <label
                  htmlFor="privacy"
                  className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
                >
                  Privacy
                </label>
                <div className="">
                  <input
                    type="text"
                    name="privacy"
                    id="privacy"
                    onChange={(e) => {
                      e.preventDefault();
                      setFormData({ ...formData, privacy: e.target.value });
                    }}
                    value={formData.privacy}
                    className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                  />
                </div>
              </div>
            </div>

            {/* <div className="tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
              <label
                htmlFor="description"
                className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:tw-mt-px tw-sm:tw-pt-2"
              >
                Description
              </label>
              <div className="tw-mt-1 tw-sm:tw-mt-0 tw-sm:tw-col-span-2">
                <textarea
                  type={"text"}
                  name="description"
                  id="description"
                  onChange={(e) => {
                    e.preventDefault();
                    setFormData({ ...formData, description: e.target.value });
                  }}
                  value={formData.description}
                  className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                />
              </div>
            </div> */}
            <TextArea
              label={`description`}
              name={"description"}
              value={formData.description}
              onChange={(value) => {
                setFormData({ ...formData, description: value });
              }}
              maxLength={200}
            />

            {/* <div className="tw-tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
              <label
                htmlFor="insights"
                className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:tw-mt-px tw-sm:tw-pt-2"
              >
                Insights
              </label>
              <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
                <input
                  type="textarea"
                  name="insights"
                  id="insights"
                  onChange={(e) => {
                    e.preventDefault();
                    setFormData({ ...formData, insights: e.target.value });
                  }}
                  value={formData.insights}
                  className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                />
              </div>
            </div> */}

            <TextArea
              label={`insights`}
              name={"insights"}
              value={formData.insights}
              onChange={(value) => {
                setFormData({ ...formData, insights: value });
              }}
              maxLength={200}
            />

            {mode !== "read" && (
              <div className="tw-pt-5">
                <div className="tw-flex tw-justify-end">
                  <button
                    type="submit"
                    className="tw-bg-purple-200 tw-ml-3 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white hover:tw-bg-purple-700  focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-400"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default AddTopic;
