import React, { useState, useEffect, useCallback } from "react";
import AddAppointment from "./AddAppointment";
import Search from "./Search";

import AppointmentInfo from "./AppointmentInfo";

function Appointments() {
  let [workshopList, setWorkshopList] = useState([]);
  let [query, setQuery] = useState("");
  let [sortBy, setSortBy] = useState("petName");
  let [orderBy, setOrderBy] = useState("asc");

  let filteredWorkshops = workshopList
    .filter((item) => {
      return (
        item.petName.toLowerCase().includes(query.toLowerCase()) ||
        item.ownerName.toLowerCase().includes(query.toLowerCase()) ||
        item.aptNotes.toLowerCase().includes(query.toLowerCase())
      );
    })
    .sort((a, b) => {
      let order = orderBy === "asc" ? 1 : -1;
      return a[sortBy].toLowerCase() < b[sortBy].toLowerCase()
        ? -1 * order
        : 1 * order;
    });

  const fetchData = useCallback(() => {
    fetch("./data.json")
      .then((response) => response.json()) // TODO
      .then((data) => {
        // TODO
        setWorkshopList(data);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <AddAppointment
        onSendWorkshop={(myWorkshop) =>
          setWorkshopList([...workshopList, myWorkshop])
        }
        lastId={workshopList.reduce(
          (max, item) => (Number(item.id) > max ? Number(item.id) : max),
          0
        )}
      />
      <div className="tw-App tw-container tw-mx-auto tw-mt-3 tw-font-thin">
        <Search
          query={query}
          onQueryChange={(myQuery) => setQuery(myQuery)}
          orderBy={orderBy}
          onOrderByChange={(myOrderBy) => setOrderBy(myOrderBy)}
          sortBy={sortBy}
          onSortByChange={(mySortBy) => setSortBy(mySortBy)}
        />
        <ul className="tw-divide-y tw-divide-gray-200">
          {filteredWorkshops.map((item) => (
            <AppointmentInfo
              key={item.id}
              info={item}
              onDeleteAppointment={(itemId) =>
                setWorkshopList(
                  workshopList.filter((workshop) => workshop.id !== itemId)
                )
              }
            />
          ))}
        </ul>
      </div>
    </>
  );
}

export default Appointments;
