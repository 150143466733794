import { MdShare } from "react-icons/md";
import { FcDiploma1, FcSurvey, FcComboChart, FcList } from "react-icons/fc";

const Shares = () => {
  return (
    <>
      <div className="tw-bg-white p-3 tw-shadow-sm tw-rounded-sm">
        <h4 className="tw-flex tw-text-5xl font-weight-light tw-mt-8">
          <MdShare color={"#93C5FD"} />
          <span>My Shares</span>
        </h4>
        <div className="tw-flex tw-flex-wrap tw-justify-around">
          <div className="tw-flex tw-items-center tw-space-x-2 tw-font-thin tw-text-3xl tw-text-gray-900 tw-leading-8 tw-mb-3">
            <FcDiploma1 />
            <span className="tw-tracking-wide">Events</span>
          </div>

          <div className="tw-flex tw-items-center tw-space-x-2 tw-font-thin tw-text-3xl tw-text-gray-900 tw-leading-8 tw-mb-3">
            <FcSurvey />
            <span className="tw-tracking-wide">Topics</span>
          </div>
        </div>
      </div>
      <div className="tw-my-4"></div>
      <div className="tw-bg-white p-3 tw-shadow-sm tw-rounded-sm">
        <div className="tw-flex tw-justify-around">
          <div className="tw-flex tw-flex-wrap tw-items-center tw-space-x-2 tw-font-thin tw-text-3xl tw-text-gray-900 tw-leading-8 tw-mb-3">
            <FcComboChart />
            <span className="tw-tracking-wide">Quiz & Polls</span>
          </div>

          <div className="tw-flex tw-items-center tw-space-x-2 tw-font-thin tw-text-3xl tw-text-gray-900 tw-leading-8 tw-mb-3">
            <FcList />
            <span className="tw-tracking-wide">Notes</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shares;
