import { useState } from "react";

const DeleteItem = ({ itemLabel, itemReference, onDelete, onCancel }) => {
  let [isConfirmed, setConfirmation] = useState(false);
  let [confirm, setValue] = useState("");
  return (
    <div
      className="tw-min-w-screen tw-h-screen tw-animated tw-fadeIn tw-faster  tw-fixed  tw-left-0 tw-top-0 tw-flex tw-justify-center tw-items-center tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none tw-bg-no-repeat tw-bg-center tw-bg-cover"
      id="modal-id"
    >
      <div className="tw-absolute tw-bg-black tw-opacity-30 tw-inset-0 tw-z-0"></div>
      <div className="tw-w-full  tw-max-w-lg tw-p-5 tw-relative tw-mx-auto tw-my-auto tw-rounded-xl tw-shadow-lg  tw-bg-white ">
        <div className="">
          <div className="tw-text-center tw-p-5 tw-flex-auto tw-justify-center">
            <h2 className="tw-text-xl tw-font-bold tw-py-4 ">Are you sure?</h2>
            <p className="tw-text-sm tw-text-gray-500 tw-px-8">
              Do you really want to delete {itemLabel}? <br />
              <span className="tw-capitalize">
                {itemLabel} Reference Id: {itemReference}
              </span>
            </p>

            <div className="tw-tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
              <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
                <input
                  type="text"
                  name="confirm"
                  id={"confirm" + itemReference}
                  onChange={(e) => {
                    e.preventDefault();
                    setValue(e.target.value);
                    if (e.target.value === "delete") {
                      setConfirmation(true);
                    } else {
                      setConfirmation(false);
                    }
                  }}
                  value={confirm}
                  placeholder="Type delete"
                  className="tw-text-center tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                />
              </div>
            </div>

            {isConfirmed && (
              <p className="tw-mt-4 tw-text-sm tw-text-red-500 tw-px-8">
                This process cannot be undone!
              </p>
            )}
          </div>
          <div className="tw-p-3  tw-mt-2 tw-text-center tw-space-x-4 md:tw-block">
            <button
              className="tw-mb-2 tw-md:tw-mb-0 tw-bg-white tw-px-5 tw-py-2 tw-text-sm tw-shadow-sm tw-font-medium tw-tracking-wider tw-border tw-text-gray-600 tw-rounded-full hover:tw-shadow-lg hover:tw-bg-gray-100"
              onClick={onCancel}
            >
              Cancel
            </button>
            {isConfirmed && (
              <button
                className="tw-mb-2 md:tw-mb-0 tw-bg-red-500 tw-border tw-border-red-500 tw-px-5 tw-py-2 tw-text-sm tw-shadow-sm tw-font-medium tw-tracking-wider tw-text-white tw-rounded-full hover:tw-shadow-lg hover:tw-bg-red-600"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(itemReference);
                }}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteItem;
