import React, { useRef, useState } from "react";
import JoditEditor from "jodit-react";
import Submit from "./forms/Submit";
import { useHistory, useLocation } from "react-router";
import { firestore, MERGE_FIELDS, ARRAY_UNION } from "../modules/Firebase";
import { toast } from "react-toastify";

const RichText = ({
  userId,
  size,
  isEdit,
  notesId,
  data,
  itemReference,
  onComplete,
}) => {
  const history = useHistory();
  const location = useLocation();
  const editor = useRef(null);
  const [content, setContent] = useState(isEdit ? data.content : "");

  let mode;
  if (isEdit !== true) {
    size = location.size;
    itemReference = location.reference;
    userId = location.state;
    mode = "write";
  } else {
    mode = userId === data.createdBy ? "write" : "read";
  }

  let height = size === "md" ? 500 : 100;
  if (size === "lg") height = 800;

  const config = {
    readOnly: false,
    height: height,
  };
  const handleUpdate = (newContent) => {
    setContent(newContent);
  };

  const submitRichText = () => {
    const notesCollection = firestore.collection("notes");
    let actionString = "created";
    let action = null;
    if (isEdit) {
      action = MERGE_FIELDS("notes", notesId, {
        content: content,
      });
      actionString = "updated";
    } else {
      action = notesCollection.add({
        createdBy: userId,
        privacy: "private",
        content: content,
      });
    }

    action
      .then((results) => {
        toast(`Notes details successfully ${actionString}!!!`);
        setContent("");
        if (isEdit) {
          onComplete();
        } else {
          let notesId = results.id;
          let userRef = firestore.collection("users").doc(userId);
          ARRAY_UNION(userRef, "notes", notesId)
            .then((res) => {
              // TODO
              location.onComplete(notesId, location.reference);
              history.goBack();
            })
            .catch((error) => {
              // TODO
              toast.error(
                `Error occurred while inserting Notes Id to User Doc: ${error.message}`
              );
            });
        }
      })
      .catch((error) => {
        // TODO
        toast.error(`Error occurred while creating notes: ${error.message}`);
      });
  };

  return (
    <>
      <div className="tw-flex">
        <div className="">
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={handleUpdate}
            onChange={(newContent) => {}}
          />
        </div>
        <div
          className="tw-mx-5 tw-bg-white"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
      {mode === "write" && (
        <Submit
          label="Save"
          onSubmit={() => {
            submitRichText();
          }}
        />
      )}
    </>
  );
};

export default RichText;
