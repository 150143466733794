import { useState } from "react";
import {
  FcComboChart,
  FcPackage,
  FcOk,
  FcQuestions,
  FcSurvey,
  FcIdea,
  FcDiploma1,
} from "react-icons/fc";

const ActivitiesTimeline = ({
  workshop,
  topics,
  onClick,
  checkInData,
  resetTopicId,
}) => {
  const [activityCount, setActivityCount] = useState(0);
  const [selectedTopicId, setSelectedTopicId] = useState(resetTopicId);
  const left = (title, topicId) => {
    return (
      <div className="tw-mt-6 sm:tw-mt-5 sm:tw--mb-15">
        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center">
          <div className="tw-flex tw-justify-start tw-w-full tw-mx-auto tw-items-center">
            <div className="tw-w-full sm:tw-w-2/3 sm:tw-pr-8">
              <button
                className={
                  "tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left tw-rounded-md"
                }
              >
                <div>
                  <FcSurvey size="25" />
                  <span className="tw-text-semibold tw-text-gray-800 tw-capitalize">
                    {title}
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="tw-rounded-full tw-bg-white tw-border-white tw-border-4 tw-absolute tw-left-2/3 tw--translate-y-4 sm:tw-translate-y-0 tw-transform tw--translate-x-1/2 tw-flex tw-items-center tw-justify-center">
            <FcDiploma1 size="30" />
          </div>
        </div>
      </div>
    );
  };

  const right = (type, topicId, id) => {
    let isCompleted = false;
    let data = null;
    if (["quiz", "poll"].includes(type) && id === null) {
      return;
    }
    switch (type) {
      case "poll":
        if (checkInData && checkInData.pollResults !== "undefined")
          data = checkInData.pollResults.filter((poll) => poll.ref === id)[0];
        break;
      case "quiz":
        if (checkInData && checkInData.quizResults !== "undefined")
          data = checkInData.quizResults.filter((poll) => poll.ref === id)[0];
        break;

      case "insights":
        if (checkInData && checkInData.insightsResults !== "undefined")
          data = checkInData.insightsResults.filter(
            (insight) => insight.ref === topicId
          )[0];
        break;
      default:
        break;
    }
    if (data) {
      isCompleted = true;
    }
    return (
      <div className="tw-mt-2 sm:tw-mt-4 sm:tw-mb-2">
        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center">
          <div className="tw-flex tw-justify-end tw-w-full tw-mx-auto tw-items-center">
            <div className="tw-w-full sm:tw-w-1/3 sm:tw-pl-8">
              <button
                className={
                  "tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left tw-rounded-md"
                }
              >
                <div>
                  {(type === "poll" && (
                    <FcComboChart
                      size="19"
                      className="tw-inline-block tw-align-text-top"
                    />
                  )) ||
                    (type === "quiz" && (
                      <FcPackage
                        size="19"
                        className="tw-inline-block tw-align-text-top"
                      />
                    )) ||
                    (type === "insights" && (
                      <FcIdea
                        size="19"
                        className="tw-inline-block tw-align-text-top"
                      />
                    ))}{" "}
                  <span className="tw-text-semibold tw-text-gray-800 tw-capitalize">
                    {type}
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="tw-rounded-full tw-font-xs tw-bg-white tw-border-white tw-border-4 tw-absolute tw-left-2/3 tw--translate-y-4 sm:tw-translate-y-0 tw-transform tw--translate-x-1/2 tw-flex tw-items-center tw-justify-center">
            {isCompleted ? <FcOk size="25" /> : <FcQuestions size="25" />}
          </div>
        </div>
      </div>
    );
  };

  const createTimeline = () => {
    return topics.map((item) => {
      return (
        <div
          key={item.id}
          className={
            selectedTopicId === item.id && resetTopicId !== null
              ? "tw-rounded-md tw-border-2 tw-my-2 tw-p-2 tw-border-purple-500 tw-shadow-xl tw-bg-blue-50"
              : ""
          }
          onClick={(e) => {
            e.preventDefault();
            setSelectedTopicId(item.id);
            onClick("topic", item.id, null);
          }}
        >
          {left(item.title, item.id)}
          {item.quiz.length > 0 && right("quiz", item.id, item.quiz)}
          {item.poll.length > 0 && right("poll", item.id, item.poll)}
          {right("insights", item.id, null)}
        </div>
      );
    });
  };

  return (
    <>
      <div className="tw-bg-white lg:tw-order-1 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-2 tw-mb-5 lg:tw-mb-0">
        <h1 className="tw-text-center tw-text-3xl tw-font-thin tw-capitalize tw-mb-3">
          {workshop.name}
        </h1>
        <div className="tw-mt-4 tw-text-center tw-text-sm">
          {workshop.description}
        </div>
      </div>
      <h1 className="tw-text-center tw-text-lg tw-font-thin tw-capitalize tw-font-semibold tw-mt-3">
        {/* Event Activities (1/{activityCount}) Topics Completed */}
      </h1>
      <div className="tw-min-h-screen tw-py-6 tw-flex tw-flex-col sm:tw-py-12">
        <div className="tw-px-5 sm:tw-max-w-xl sm:tw-mx-auto tw-w-full tw-px-2 sm:tw-px-0">
          <div className="tw-relative tw-text-gray-700 tw-antialiased tw-text-sm tw-font-semibold">
            {/* <!-- Vertical bar running through middle --> */}
            <div className="tw-hidden sm:tw-block tw-w-1 tw-bg-blue-300 tw-absolute tw-h-full tw-left-2/3 tw-transform tw--translate-x-1/2"></div>

            {createTimeline()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivitiesTimeline;
