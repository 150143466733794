import { BiCalendarPlus } from "react-icons/bi";
import { useState } from "react";
import { useLocation } from "react-router";
import TimezoneSelect from "react-timezone-select";

// TODO: To be refactored
const AddAppointment = ({ isEdit, schedule, onComplete, mode }) => {
  let [toggleForm, setToggleForm] = useState(true);
  let clearData = {
    organizerName: "",
    name: "",
    aptDate: "",
    aptTime: "",
    aptNotes: "",
    disableOwnerName: false,
    disableName: false,
  };
  let location = useLocation();
  const [selectedTimeZone, setSelectedTimezone] = useState({});

  if (location.state.displayName) {
    clearData.organizerName = location.state.displayName;
    clearData.disableOrganizerName = true;
  }

  if (location.state.name) {
    clearData.name = location.state.name;
    clearData.disableName = true;
  }
  if (location.state.isEdit || isEdit === true) {
    clearData.aptDate = location.state.date;
    clearData.aptTime = location.state.time;
    clearData.aptNotes = location.state.notes;
  }

  let [formData, setFormData] = useState(clearData);

  function formDataPublish(id) {
    const workshopInfo = {
      id: location.state.id,
      date: formData.aptDate,
      time: formData.aptTime,
      timeZone: selectedTimeZone,
      notes: formData.aptNotes,
    };
    if (isEdit) {
      schedule(workshopInfo);
    } else {
      location.schedule(workshopInfo);
    }

    setFormData(clearData);
    setToggleForm(!toggleForm);
    if (location.onComplete) {
      location.onComplete(workshopInfo);
    }
    if (isEdit) {
      onComplete(workshopInfo);
    }
  }

  return (
    <div className="tw-App tw-container tw-mx-auto tw-mt-3 tw-font-thin">
      <button
        onClick={() => setToggleForm(!toggleForm)}
        className={
          "tw-bg-purple-200  tw-px-2 tw-py-3 tw-w-full tw-text-left " +
          (toggleForm === true ? "tw-rounded-t-md" : "tw-rounded-md")
        }
      >
        <div className="tw-text-4xl tw-text-gray-800 tw-font-thin">
          <BiCalendarPlus className="tw-inline-block tw-align-text-top" />{" "}
          {isEdit ? "Reschedule Event" : "Schedule Event"}
        </div>
      </button>
      {toggleForm && (
        <div className="tw-bg-white tw-border-r-2 tw-border-b-2 tw-border-l-2 tw-border-light-blue-500 tw-rounded-b-md tw-pl-4 tw-pr-4 tw-pb-4">
          {/* <div className="tw-tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
            <label
              htmlFor="organizerName"
              className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
            >
              Convenor Name
            </label>
            <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
              <input
                type="text"
                name="organizerName"
                id="organizerName"
                onChange={(e) => {
                  setFormData({ ...formData, organizerName: e.target.value });
                }}
                disabled={formData.disableOrganizerName}
                value={formData.organizerName}
                className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </div> */}

          <div className="tw-tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
            <label
              htmlFor="name"
              className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
            >
              Workshop Name
            </label>
            <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
              <input
                type="text"
                name="name"
                id="name"
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                }}
                value={formData.name}
                disabled={formData.disableName}
                className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </div>

          <div className="tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
            <label
              htmlFor="aptDate"
              className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
            >
              Date
            </label>
            <div className="tw-mt-1 tw-sm:tw-mt-0 tw-sm:tw-col-span-2">
              <input
                type="date"
                name="aptDate"
                id="aptDate"
                onChange={(e) => {
                  setFormData({ ...formData, aptDate: e.target.value });
                }}
                value={formData.aptDate}
                className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </div>

          <div className="tw-tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
            <label
              htmlFor="aptTime"
              className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
            >
              Time
            </label>
            <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
              <input
                type="time"
                name="aptTime"
                id="aptTime"
                onChange={(e) => {
                  setFormData({ ...formData, aptTime: e.target.value });
                }}
                value={formData.aptTime}
                className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
              />
            </div>
          </div>
          <div className="tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start tw-sm:tw-pt-5">
            <label
              htmlFor="aptNotes"
              className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
            >
              Select Timezone
            </label>
            <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
              <TimezoneSelect
                value={selectedTimeZone}
                onChange={setSelectedTimezone}
              />
            </div>
          </div>

          {/* <div className="tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start tw-sm:tw-pt-5">
            <label
              htmlFor="aptNotes"
              className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
            >
              Workshop Objectives
            </label>
            <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
              <textarea
                id="aptNotes"
                name="aptNotes"
                rows="3"
                className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                placeholder="Detailed comments about the condition"
                onChange={(e) => {
                  setFormData({ ...formData, aptNotes: e.target.value });
                }}
                value={formData.aptNotes}
              ></textarea>
            </div>
          </div> */}
          {mode !== "read" && (
            <div className="tw-pt-5">
              <div className="tw-flex tw-justify-end">
                <button
                  type="submit"
                  onClick={formDataPublish}
                  className="tw-bg-purple-200 tw-ml-3 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-gray-800 tw-bg-purple-200 hover:tw-bg-purple-700  focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-400"
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddAppointment;
