const IconButton = ({ size, label, title, icon, type, onClick, highlight }) => {
  let buttonWidth = "tw-w-20 tw-p-3   ";
  if (label && label.length > 0) {
    buttonWidth = "tw-w-40 tw-h-10 ";
  }

  if (size === "xs") {
    buttonWidth = "tw-w-40 tw-h-10 ";
  }

  if (size === "xl") {
    buttonWidth = "tw-w-80 tw-p-3 tw-h-1";
  }
  if (size === "lg") {
    buttonWidth = "tw-w-28 tw-p-10 tw-h-10";
  }

  let bgColor = "tw-bg-white tw-text-blue-500 ";
  if (highlight) {
    bgColor = "tw-bg-blue-300 tw-text-white ";
  }
  return (
    <button
      className="tw-flex tw-m-2 tw-bg-grey-lighter"
      title={title}
      type={type}
      onClick={onClick}
    >
      <label
        className={
          buttonWidth +
          bgColor +
          "tw-flex tw-flex-col tw-items-center tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-uppercase tw-border tw-border-blue-300 tw-cursor-pointer hover:tw-bg-blue-300 hover:tw-text-white"
        }
      >
        {icon}

        {label && (
          <span className="tw-mt-2 tw-text-xs tw-leading-normal tw-font-semibold">
            {label}
          </span>
        )}
      </label>
    </button>
  );
};
// tw-items-center tw-justify-center
export default IconButton;
