import { BiSearch, BiCaretDown, BiCheck } from "react-icons/bi";

import { useState } from "react";

// https://github.com/LinkedInLearning/react-interface-2880067/wiki/Drop-Down-JSX
const DropDown = ({ sortBy, onSortByChange, orderBy, onOrderByChange }) => {
  return (
    <div className="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5">
      <div
        className="tw-py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div
          onClick={() => onSortByChange("displayName")}
          className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-flex tw-justify-between tw-cursor-pointer"
          role="menuitem"
        >
          Participant Name {sortBy === "displayName" && <BiCheck />}
        </div>
        <div
          onClick={() => onOrderByChange("asc")}
          className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-flex tw-justify-between tw-cursor-pointer tw-border-gray-1 tw-border-t-2"
          role="menuitem"
        >
          Ascending {orderBy === "asc" && <BiCheck />}
        </div>
        <div
          onClick={() => onOrderByChange("desc")}
          className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-flex tw-justify-between tw-cursor-pointer"
          role="menuitem"
        >
          Descending {orderBy === "desc" && <BiCheck />}
        </div>
      </div>
    </div>
  );
};

// https://github.com/LinkedInLearning/react-interface-2880067/wiki/Search-Component-JSX
const SearchParticipants = ({
  query,
  onQueryChange,
  sortBy,
  onSortByChange,
  orderBy,
  onOrderByChange,
  toggleAdjust,
}) => {
  let [toggleSort, setToggleSort] = useState(false);

  return (
    <div className="tw-py-5">
      <div className="tw-relative tw-rounded-md tw-shadow-sm">
        <div className="tw-absolute tw-inset-y-1 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
          <BiSearch />
          <label htmlFor="query" className="tw-sr-only" />
        </div>
        <input
          type="text"
          name="query"
          id="query_participants"
          value={query}
          onChange={(e) => {
            onQueryChange(e.target.value);
          }}
          className="tw-pl-8 tw-py-3 tw-rounded-md focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-block tw-w-full tw-lg:tw-text-lg border-gray-300"
          placeholder="SEARCH PARTICIPANTS"
        />
        <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center">
          <div>
            <button
              type="button"
              onClick={() => {
                setToggleSort(!toggleSort);
                toggleAdjust(!toggleSort);
              }}
              className="tw-justify-center tw-px-4 tw-py-3 tw-bg-blue-400 tw-border-1 tw-border-blue-400 tw-text-sm tw-text-white hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 tw-flex tw-items-center"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
            >
              Sort By <BiCaretDown className="tw-ml-2" />
            </button>
            {toggleSort && (
              <DropDown
                sortBy={sortBy}
                onSortByChange={(mySort) => onSortByChange(mySort)}
                orderBy={orderBy}
                onOrderByChange={(myOrder) => onOrderByChange(myOrder)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchParticipants;
