import { useState } from "react";
import firebase, { storage } from "../modules/Firebase";
import { GoCloudUpload } from "react-icons/go";
import { toast } from "react-toastify";

const FileUpload = ({ label, itemRef, metaData, destRef, onComplete }) => {
  const [uploadStatus, setUploadStatus] = useState({
    image: null,
    progress: 0,
    downloadURL: null,
  });

  function upload(file, name, type) {
    let timestamp = Date.now();
    name = timestamp + "_" + name;

    let imageRef = storage.ref(`${destRef}/${name}`);
    let metadata = { ...metaData, contentType: type };

    let uploadTask = imageRef.put(file, metadata);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        var progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadStatus({
          ...uploadStatus,
          progress,
        });
      },
      (error) => {
        throw error;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          // TODO
          toast("Image uploaded successfully!!!", { autoClose: 5000 });
          setUploadStatus({
            ...uploadStatus,
            downloadURL: url,
          });
          onComplete(
            uploadTask.snapshot,
            url,
            type,
            imageRef.fullPath,
            itemRef
          );
        });
      }
    );
  }

  let handleChange = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      upload(e.target.files[0], e.target.files[0].name, e.target.files[0].type);
    }
  };

  return (
    <div className="tw-flex tw-bg-grey-lighter">
      <label className="tw-w-20 tw-flex tw-flex-col tw-items-center tw-h-20 tw-p-8 tw-bg-white tw-text-blue-500 tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-uppercase tw-border tw-border-blue-300 tw-cursor-pointer hover:tw-bg-blue-300 hover:tw-text-white">
        {uploadStatus.progress === 0 && <GoCloudUpload />}
        {uploadStatus.progress > 0 && (
          <span className="tw-text-sm tw-leading-normal tw-font-thin">
            {uploadStatus.progress}%
          </span>
        )}
        {label && (
          <span className="tw-mt-2 tw-text-sm tw-leading-normal tw-font-thin">
            {label}
          </span>
        )}
        <input
          type="file"
          className="tw-hidden"
          name="file"
          onChange={handleChange}
        />
      </label>
    </div>
  );
};
// tw-items-center tw-justify-center
export default FileUpload;
