import React from "react";
import { withRouter } from "react-router-dom"; // import './App.css';
import { toast } from "react-toastify";

import { auth } from "../Firebase";
import FormError from "../../components/FormError";
import PleaseWait from "../../components/PleaseWait";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMessage: null,
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState({
      [itemName]: itemValue,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let loginInfo = {
      email: this.state.email,
      password: this.state.password,
    };

    let that = this;
    this.setState({ ...this.state, isLoading: true });

    auth
      .signInWithEmailAndPassword(loginInfo.email, loginInfo.password)
      .then((FBUser) => {
        // TODO
        this.setState({ ...this.state, isLoading: false });
        that.props.history.push({
          pathname: `/user/${FBUser.user.uid}`,
          state: FBUser.user.displayName,
        });
      })
      .catch((error) => {
        if (error.message !== null) {
          toast.error(`Login Failure: ${error.message}`);
          this.setState({
            errorMessage: error.message,
            isLoading: false,
          });
        } else {
          this.setState({
            errorMessage: null,
            isLoading: false,
          });
        }
      });
  }

  render() {
    return (
      <>
        <form className="mt-3" onSubmit={this.handleSubmit}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card bg-light">
                  <div className="card-body">
                    <h3 className="font-weight-light mb-3">Log in</h3>
                    {this.state.errorMessage && (
                      <FormError theMessage={this.state.errorMessage} />
                    )}
                    <section className="form-group mt-1">
                      <label
                        className="form-control-label sr-only"
                        htmlFor="Email"
                      >
                        Email
                      </label>
                      <input
                        required
                        className="form-control"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </section>
                    <section className="form-group mt-2">
                      <input
                        required
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                    </section>
                    <div className="form-group text-end mt-2 mb-0">
                      <button className="btn btn-primary" type="submit">
                        Log in
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {this.state.isLoading && <PleaseWait />}
      </>
    );
  }
}

export default withRouter(Login);
