import { useState, useEffect, useCallback } from "react";
import { FcIdea, FcOk } from "react-icons/fc";
import Submit from "../../../components/forms/Submit";
import { ARRAY_REMOVE, ARRAY_UNION, firestore } from "../../Firebase";
import TextArea from "../../../components/forms/TextArea";

const AddInsights = ({
  parentReference,
  itemReference,
  type,
  userId,
  onComplete,
  checkInData,
}) => {
  const [togglePoll, setTogglePoll] = useState(true);
  const [isCompleted, setCompleted] = useState(false);
  const [showError, setShowError] = useState(false);
  const [insights, setInsights] = useState("");
  const [previousInsights, setPreviousInsights] = useState(null);

  const findCompleted = useCallback(() => {
    let insightsResults = checkInData.insightsResults.filter(
      (checkIns) => checkIns.ref === itemReference
    );
    if (insightsResults.length > 0) {
      setCompleted(true);
      setTogglePoll(false);
      setInsights(insightsResults[0].insights);
      setPreviousInsights(insightsResults[0]);
    }
  }, []);

  useEffect(() => {
    findCompleted();
  }, [userId, findCompleted]);

  const addInsights = () => {
    if (insights.length === 0) {
      setShowError(true);
      return;
    }

    let parentRef = firestore.collection(type).doc(itemReference);
    let checkInRef = firestore.collection("checkIns").doc(checkInData.id);
    if (isCompleted) {
      ARRAY_REMOVE(parentRef, "insightsResults", {
        uid: userId,
        type: type,
        insights: previousInsights.insights,
      });
      ARRAY_REMOVE(checkInRef, "insightsResults", previousInsights);
    }

    ARRAY_UNION(parentRef, "insightsResults", {
      uid: userId,
      type: type,
      insights: insights,
    }).then((response) => {
      setCompleted(true);

      ARRAY_UNION(checkInRef, "insightsResults", {
        ref: itemReference,
        type: type,
        insights: insights,
      });
    });

    onComplete("insights");

    setShowError(false);
    setTogglePoll(!togglePoll);
  };

  return (
    <div className="tw-w-96 tw-container tw-p-2 tw-mx-auto tw-mt-3 tw-font-thin">
      <button
        onClick={() => setTogglePoll(!togglePoll)}
        className={
          "tw-flex tw-justify-between tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left " +
          (togglePoll === true ? "tw-rounded-t-md" : "tw-rounded-md")
        }
      >
        <div className="tw-text-4xl tw-text-gray-800 tw-font-thin tw-capitalize">
          <FcIdea className="tw-inline-block tw-align-text-top" /> Insights
        </div>
        <div className="tw-text-normal tw-text-gray-800 tw-font-thin tw-capitalize">
          {isCompleted && <FcOk />}
        </div>
      </button>
      {togglePoll && (
        <div className="tw-rounded-md tw-border-2  tw-p-2 tw-border-purple-100 tw-bg-white">
          <TextArea
            label={"Add Insights"}
            name={"name"}
            value={insights}
            onChange={(value) => {
              setInsights(value);
            }}
            maxLength={250}
          />
          {showError && (
            <p className="tw-text-red-500 tw-text-right tw-font-thin">
              Empty insights field, Please add your thoughts.
            </p>
          )}
          <Submit
            label={(isCompleted ? "Update " : "Add ") + "Insights"}
            onSubmit={addInsights}
          />
        </div>
      )}
    </div>
  );
};

export default AddInsights;
