import { useState } from "react";
import { AiOutlinePicture } from "react-icons/ai";
import { GoTrashcan } from "react-icons/go";
import EditItem from "./EditItem";
const ImagePreview = ({
  itemLabel,
  itemDetail,
  itemRef,
  fullPath,
  url,
  onDelete,
  size,
  mode,
  showPic,
}) => {
  let [preview, setPreview] = useState(false);
  let [confirmDelete, showDeleteConfirmation] = useState(false);
  const content = (
    <div className="tw-flex tw-items-center tw-p-2 tw-bg-white tw-text-blue-500 tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-border tw-border-blue-300 tw-cursor-pointer">
      <img className={"tw-w-3/6 tw-object-cover"} src={url} alt="Url Broken" />
      <div className="tw-w-3/6  tw-max-w-lg tw-relative tw-mx-auto tw-my-auto tw-rounded-xl tw-shadow-lg  tw-bg-white ">
        {!confirmDelete && (
          <>
            <div className="tw-text-center tw-p-5 tw-flex-auto tw-justify-center">
              <h2 className="tw-text-xl tw-font-bold">
                Image Preview of {itemLabel}
              </h2>
              <p className="tw-text-lg tw-text-gray-500 tw-px-8">
                {itemDetail}
              </p>
            </div>
            {mode !== "read" && onDelete && (
              <button
                className="btn btn-sm tw-mx-72"
                title="Delete Image"
                onClick={(e) => {
                  e.preventDefault();
                  showDeleteConfirmation(true);
                }}
              >
                <GoTrashcan />
              </button>
            )}
          </>
        )}

        {confirmDelete && (
          <div className="tw-px-5">
            <div className="tw-text-center tw-p-5 tw-flex-auto tw-justify-center">
              <h2 className="tw-text-xl tw-font-bold tw-py-4 ">
                Are you sure?
              </h2>
              <p className="tw-text-sm tw-text-gray-500 tw-px-8">
                Do you really want to delete this image? <br />
                {/* <span className="tw-capitalize">Image Reference Id: {url}</span> */}
              </p>
              {confirmDelete && (
                <p className="tw-mt-4 tw-text-sm tw-text-red-500 tw-px-8">
                  This process cannot be undone!
                </p>
              )}
            </div>
            <div className="tw-p-3  tw-mt-2 tw-text-center tw-space-x-4 md:tw-block">
              <button
                className="tw-mb-2 tw-md:tw-mb-0 tw-bg-white tw-px-5 tw-py-2 tw-text-sm tw-shadow-sm tw-font-medium tw-tracking-wider tw-border tw-text-gray-600 tw-rounded-full hover:tw-shadow-lg hover:tw-bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  showDeleteConfirmation(false);
                }}
              >
                Cancel
              </button>
              {confirmDelete && (
                <button
                  className="tw-mb-2 md:tw-mb-0 tw-bg-red-500 tw-border tw-border-red-500 tw-px-5 tw-py-2 tw-text-sm tw-shadow-sm tw-font-medium tw-tracking-wider tw-text-white tw-rounded-full hover:tw-shadow-lg hover:tw-bg-red-600"
                  onClick={(e) => {
                    e.preventDefault();
                    onDelete(fullPath, itemRef);
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
  return (
    <div className="tw-flex tw-bg-grey-lighter">
      <div
        className={
          showPic
            ? "tw-w-20 tw-h-20 "
            : "tw-w-10 tw-h-9 tw--my-12 " +
              "tw-flex tw-flex-col tw-items-center tw-p-2 tw-bg-white tw-text-blue-500 tw-rounded-lg tw-shadow-lg tw-tracking-wide tw-uppercase tw-border tw-border-blue-300 tw-cursor-pointer hover:tw-bg-blue-300 hover:tw-text-white"
        }
      >
        {/* <ImFilePicture /> */}
        <button
          onClick={(e) => {
            e.preventDefault();
            setPreview(true);
          }}
        >
          {showPic ? (
            <img className={"tw-object-cover"} src={url} alt="Url Broken" />
          ) : (
            <AiOutlinePicture />
          )}
        </button>
      </div>
      {preview && (
        <EditItem
          content={content}
          onCancel={(e) => {
            e.preventDefault();
            setPreview(false);
          }}
        />
      )}
    </div>
  );
};

export default ImagePreview;
