const Submit = ({ label, onSubmit }) => {
  let isSubmitButton = onSubmit !== undefined ? false : true;
  return (
    <div className="tw-pt-5">
      <div className="tw-flex tw-justify-end">
        {isSubmitButton && (
          <button
            type="submit"
            className="tw-bg-purple-400 tw-ml-3 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white hover:tw-bg-purple-700  focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-400"
          >
            {label}
          </button>
        )}
        {!isSubmitButton && (
          <button
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            className="tw-bg-purple-400 tw-ml-3 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white hover:tw-bg-purple-700  focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-400"
          >
            {label}
          </button>
        )}
      </div>
    </div>
  );
};

export default Submit;
