import { useContext } from "react";
import { FaBars, FaTrain } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ProfileProvider } from "../contexts/ProfileContext";

//https://codesandbox.io/s/dy13o?file=/src/Nav.js:0-6206
const Nav = ({ user, userId, logoutUser }) => {
  let profile = useContext(ProfileProvider);
  console.log("Profile in Nav", profile);
  return (
    <div className="">
      <div className="tw-antialiased tw-dark-mode:tw-bg-blue-100 tw-sticky tw-pb-20">
        <div className="tw-w-full  tw-shadow-lg tw-text-gray-700 tw-bg-blue-100 tw-dark-mode:tw-text-gray-200 tw-dark-mode:tw-bg-gray-800 tw-fixed tw-top-0 tw-z-50">
          <div
            x-data="{ tw-open: false }"
            className="tw-flex tw-flex-col tw-max-w-screen-xl tw-px-4 tw-mx-auto md:tw-items-center md:tw-justify-between md:tw-flex-row md:tw-px-6 lg:tw-px-8"
          >
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-4">
              <Link
                to="/"
                className="tw-flex tw-text-white tw-text-lg tw-font-semibold tw-tracking-widest tw-text-gray-700 tw-uppercase tw-rounded-lg tw-dark-mode:tw-text-white focus:tw-outline-none focus:tw-shadow-outline"
              >
                <FaTrain className="tw-mx-2 tw-mt-1" /> Event Central
              </Link>
              <button className="tw-rounded-lg px-3 py-2  md:tw-hidden tw-border-2 tw-rounded focus:tw-outline-none focus:tw-shadow-outline">
                <FaBars />
              </button>
            </div>
            {/* <nav :className="{'tw-flex': open, 'hidden': !open}" className="tw-flex-col tw-flex-grow hidden tw-pb-4 md:tw-pb-0 md:tw-flex md:tw-justify-end md:tw-flex-row"> */}
            <nav className="tw-flex-col tw-flex-grow tw-hidden tw-pb-4 md:tw-pb-0 md:tw-flex md:tw-justify-end md:tw-flex-row">
              {user && (
                <>
                  <Link
                    className="tw-px-4 tw-py-2 tw-mt-2 tw-text-sm tw-font-semibold tw-bg-transparent tw-rounded-lg tw-dark-mode:tw-bg-transparent tw-dark-mode:hover:tw-bg-gray-600 dark-mode:focus:tw-bg-gray-600 dark-mode:focus:tw-text-white dark-mode:hover:tw-text-white dark-mode:tw-text-gray-200 md:tw-mt-0 md:ml-4 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline"
                    to={`/user/${userId}`}
                  >
                    My Home
                  </Link>
                  {/* <Link
                    className="tw-px-4 tw-py-2 tw-mt-2 tw-text-sm tw-font-semibold tw-bg-transparent tw-rounded-lg tw-dark-mode:tw-bg-transparent tw-dark-mode:hover:tw-bg-gray-600 dark-mode:focus:tw-bg-gray-600 dark-mode:focus:tw-text-white dark-mode:hover:tw-text-white dark-mode:tw-text-gray-200 md:tw-mt-0 md:ml-4 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline"
                    to="/register"
                  >
                    Register
                  </Link> */}
                  <Link
                    className="tw-px-4 tw-py-2 tw-mt-2 tw-text-sm tw-font-semibold tw-bg-transparent tw-rounded-lg tw-dark-mode:tw-bg-transparent tw-dark-mode:hover:tw-bg-gray-600 dark-mode:focus:tw-bg-gray-600 dark-mode:focus:tw-text-white dark-mode:hover:tw-text-white dark-mode:tw-text-gray-200 md:tw-mt-0 md:ml-4 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline"
                    to="/"
                    onClick={(e) => logoutUser(e)}
                  >
                    Logout
                  </Link>
                </>
              )}
              {!user && (
                <Link
                  className="tw-px-4 tw-py-2 tw-mt-2 tw-text-sm tw-font-semibold tw-bg-transparent tw-rounded-lg tw-dark-mode:tw-bg-transparent tw-dark-mode:hover:tw-bg-gray-600 dark-mode:focus:tw-bg-gray-600 dark-mode:focus:tw-text-white dark-mode:hover:tw-text-white dark-mode:tw-text-gray-200 md:tw-mt-0 md:ml-4 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline"
                  to="#"
                >
                  Login
                </Link>
              )}
              {/* <div @click.away="open = false" className="relative" x-data="{ open: true }">
              <button @click="open = !open" className="tw-flex tw-flex-row tw-text-gray-900 tw-bg-gray-200 tw-items-center tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-sm tw-font-semibold tw-text-left tw-bg-transparent tw-rounded-lg dark-mode:tw-bg-transparent dark-mode:focus:tw-text-white dark-mode:hover:tw-text-white dark-mode:focus:tw-bg-gray-600 dark-mode:hover:tw-bg-gray-600 md:w-auto md:tw-inline md:tw-mt-0 md:ml-4 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline">
           */}
              {/* <div className="tw-relative" x-data="{ open: true }">
                <button className="tw-flex tw-flex-row tw-text-gray-900 tw-bg-gray-200 tw-items-center tw-w-full tw-px-4 tw-py-2 tw-mt-2 tw-text-sm tw-font-semibold tw-text-left tw-bg-transparent tw-rounded-lg tw-dark-mode:tw-bg-transparent tw-dark-mode:focus:tw-text-white tw-dark-mode:hover:tw-text-white tw-dark-mode:focus:tw-bg-gray-600 tw-dark-mode:hover:tw-bg-gray-600 md:tw-w-auto md:tw-inline md:tw-mt-0 md:tw-ml-4 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline">
                  <span>More</span>
                  <svg
                    fill="tw-currentColor"
                    viewBox="0 0 20 20"
                    className="tw-inline tw-w-4 tw-h-4 tw-mt-1 tw-ml-1 tw-transition-transform tw-duration-200 tw-transform md:-tw-mt-1"
                  >
                    <path
                      fillRule="tw-evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="tw-evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  x-show="tw-open"
                  //   x-transition:enter="tw-transition tw-ease-out tw-duration-100"
                  //   x-transition:enter-start="tw-transform tw-opacity-0 tw-scale-95"
                  //   x-transition:enter-end="tw-transform tw-opacity-100 tw-scale-100"
                  //   x-transition:leave="tw-transition tw-ease-in tw-duration-75"
                  //   x-transition:leave-start="tw-transform tw-opacity-100 tw-scale-100"
                  //   x-transition:leave-end="tw-transform tw-opacity-0 tw-scale-95"
                  className="tw-absolute tw-right-0 tw-w-full md:tw-max-w-screen-sm md:tw-w-screen tw-mt-2 tw-origin-top-right"
                >
                  <div className="tw-px-2 tw-pt-2 tw-pb-4 tw-bg-white tw-rounded-md tw-shadow-lg tw-dark-mode:tw-bg-gray-700">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <a
                        className="tw-flex tw-flex tw-row tw-items-start tw-rounded-lg tw-bg-transparent tw-p-2 tw-dark-mode:hover:tw-bg-gray-600 tw-dark-mode:focus:tw-bg-gray-600 tw-dark-mode:focus:tw-text-white tw-dark-mode:hover:tw-text-white tw-dark-mode:tw-text-gray-200 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline"
                        href="#"
                      >
                        <div className="tw-bg-teal-500 tw-text-white tw-rounded-lg tw-p-3">
                          <svg
                            fill="tw-none"
                            stroke="tw-currentColor"
                            strokeLinecap="tw-round"
                            strokeLinejoin="tw-round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            className="md:tw-h-6 md:tw-w-6 tw-h-4 tw-w-4"
                          >
                            <path d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path>
                          </svg>
                        </div>
                        <div className="tw-ml-3">
                          <p className="tw-font-semibold">Appearance</p>
                          <p className="tw-text-sm">Easy customization</p>
                        </div>
                      </a>

                      <a
                        className="tw-flex tw-flex tw-row tw-items-start tw-rounded-lg tw-bg-transparent tw-p-2 tw-dark-mode:hover:tw-bg-gray-600 tw-dark-mode:focus:tw-bg-gray-600 tw-dark-mode:focus:tw-text-white tw-dark-mode:hover:tw-text-white tw-dark-mode:tw-text-gray-200 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline"
                        href="#"
                      >
                        <div className="tw-bg-teal-500 tw-text-white tw-rounded-lg tw-p-3">
                          <svg
                            fill="tw-none"
                            stroke="tw-currentColor"
                            strokeLinecap="tw-round"
                            strokeLinejoin="tw-round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            className="md:h-6 md:w-6 h-4 w-4"
                          >
                            <path d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path>
                          </svg>
                        </div>
                        <div className="tw-ml-3">
                          <p className="tw-font-semibold">Comments</p>
                          <p className="tw-text-sm">
                            Check your latest comments
                          </p>
                        </div>
                      </a>

                      <a
                        className="tw-flex tw-flex tw-row tw-items-start tw-rounded-lg tw-bg-transparent tw-p-2 tw-dark-mode:hover:tw-bg-gray-600 tw-dark-mode:focus:tw-bg-gray-600 tw-dark-mode:focus:tw-text-white tw-dark-mode:hover:tw-text-white tw-dark-mode:tw-text-gray-200 hover:tw-text-gray-900 focus:tw-text-gray-900 hover:tw-bg-gray-200 focus:tw-bg-gray-200 focus:tw-outline-none focus:tw-shadow-outline"
                        href="#"
                      >
                        <div className="tw-bg-teal-500 tw-text-white tw-rounded-lg tw-p-3">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            className="md:tw-h-6 md:tw-w-6 tw-h-4 tw-w-4"
                          >
                            <path d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
                            <path d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                          </svg>
                        </div>
                        <div className="tw-ml-3">
                          <p className="tw-font-semibold">Analytics</p>
                          <p className="tw-text-sm">
                            Take a look at your statistics
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
