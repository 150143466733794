import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";

import AddTopic from "./AddTopic";
import TopicInfo from "./TopicInfo";
import SearchTopics from "./SearchTopics";
import { GET_DOC_BY_IDS } from "../../Firebase";
import EditItem from "../../../components/EditItem";

const Topics = ({ createdBy, workshopId, topicIds, mode, userId }) => {
  const [topicsList, setTopicsList] = useState([]);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState("title");
  const [orderBy, setOrderBy] = useState("asc");
  const [isEdit, setEdit] = useState(false);
  const [editInfo, setEditInfo] = useState(null);

  let filteredTopics = topicsList
    .filter((item) => {
      return (
        item.title.toLowerCase().includes(query.toLowerCase()) ||
        item.privacy.toLowerCase().includes(query.toLowerCase()) ||
        item.type.toLowerCase().includes(query.toLowerCase())
      );
    })
    .sort((a, b) => {
      let order = orderBy === "asc" ? 1 : -1;
      return a[sortBy].toLowerCase() < b[sortBy].toLowerCase()
        ? -1 * order
        : 1 * order;
    });

  const fetchTopics = useCallback(() => {
    if (topicIds && topicIds.length > 0) {
      GET_DOC_BY_IDS("topics", topicIds).onSnapshot((res) => {
        let topics = [];
        res.forEach((doc) => {
          topics.push({ ...doc.data(), id: doc.id });
        });
        setTopicsList(topics);
      });
    } else {
    }
  }, [topicIds]);

  useEffect(() => {
    fetchTopics();
  }, [topicIds, fetchTopics]);

  const onAdd = (topicId) => {
    if (!topicIds.includes(topicId)) {
      topicIds.push(topicId);
    } else {
      setEdit(false);
    }
    fetchTopics();
  };

  const onEditCancel = () => {
    setEdit(false);
  };

  const onEdit = (topicInfo) => {
    setEditInfo(topicInfo);
    setEdit(true);
  };

  const addTopic = () => {
    if (isEdit) {
      return (
        <>
          <EditItem
            content={
              <AddTopic
                createdBy={createdBy}
                workshopId={workshopId}
                onComplete={onAdd}
                isEdit={isEdit}
                data={editInfo}
                mode={mode}
              />
            }
            onCancel={onEditCancel}
          />
        </>
      );
    } else {
      return (
        mode !== "read" && (
          <>
            <AddTopic
              createdBy={createdBy}
              workshopId={workshopId}
              onComplete={onAdd}
            />
          </>
        )
      );
    }
  };

  let topics = () => {
    return (
      <div>
        {addTopic()}
        {topicIds && topicIds.length > 0 && (
          <>
            <SearchTopics
              query={query}
              onQueryChange={(myQuery) => setQuery(myQuery)}
              orderBy={orderBy}
              onOrderByChange={(myOrderBy) => setOrderBy(myOrderBy)}
              sortBy={sortBy}
              onSortByChange={(mySortBy) => setSortBy(mySortBy)}
              toggleAdjust={() => {}}
            />
            <ul className="divide-y divide-gray-400">
              {filteredTopics.map((item) => (
                <TopicInfo
                  key={item.id}
                  info={item}
                  onDelete={(itemId) =>
                    setTopicsList(
                      topicsList.filter((topic) => topic.id !== itemId)
                    )
                  }
                  onEdit={onEdit}
                  workshopId={workshopId}
                  mode={mode}
                  userId={userId}
                />
              ))}
            </ul>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="tw-px-4">
      <div className="tw-container tw-w-auto">
        <div className="tw-row tw-justify-content-center">
          <h1 className="tw-text-center tw-text-5xl tw-font-thin tw-mb-3">
            Topics
          </h1>
        </div>
      </div>
      {topics()}
    </div>
  );
};

export default withRouter(Topics);
