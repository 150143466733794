import { MdEvent } from "react-icons/md";
import WorkshopList from "../workshops/WorkshopList";

const RecentActivities = ({ next, past, userId }) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-justify-start p-3 tw-shadow-sm tw-rounded-sm">
      <div className="tw-space-x-2 tw-px-5 tw-font-thin tw-text-3xl tw-text-gray-900 tw-leading-8 tw-mb-3">
        <div className="tw-flex">
          <MdEvent color={"#93C5FD"} />
          <span className="tw-tracking-wide">Future Events</span>
        </div>
        <div className="tw-m-4 tw-border-0 tw-rounded-2">
          {next && next.length > 0 && (
            <div className="list-group list-group-flush">
              <WorkshopList
                workshops={next}
                showToolbar={false}
                userId={userId}
                mode={"read"}
                launchEvent={null}
                preview={true}
              />
            </div>
          )}
          {!next && (
            <p className="tw-text-sm">There are no future events scheduled.</p>
          )}
        </div>
      </div>
      <div className="tw-space-x-2 tw-px-5 tw-font-thin tw-text-3xl tw-text-gray-900 tw-leading-8 tw-mb-3">
        <div className="tw-flex">
          <MdEvent color={"#93C5FD"} />
          <span className="tw-tracking-wide">Past Events</span>
        </div>
        <div className="tw-m-4 tw-border-0 tw-rounded-2">
          {past && past.length > 0 && (
            <div className="list-group list-group-flush">
              <WorkshopList
                workshops={past}
                showToolbar={false}
                userId={userId}
                mode={"read"}
                launchEvent={null}
                review={true}
              />
            </div>
          )}
          {!past && (
            <p className="tw-text-sm">There are no past events attended.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentActivities;
