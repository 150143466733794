import { BiNote, BiPoll } from "react-icons/bi";
import { MdAssessment } from "react-icons/md";
import { ImEnter, ImExit } from "react-icons/im";
import { FcCollaboration, FcMindMap, FcOrganization } from "react-icons/fc";
import { GrAnnounce, GrWorkshop } from "react-icons/gr";
import { Link } from "react-router-dom";

import PleaseWait from "./PleaseWait";
// https://play.tailwindcss.com/bJGtVPu4BT
// Reference: https://gist.github.com/planetoftheweb/489491be7b93d160721e1024912dbb66
const Home = ({ user, userId, idToken, isLoading, logoutUser }) => {
  let grid =
    "tw-my-2 tw-px-2 tw-w-1/3 tw-sm:tw-w-1/3 tw-md:tw-w-1/3 tw-lg:tw-w-1/3 tw-xl:tw-w-1/3 tw-text-center";

  let authHomePage = () => {
    return (
      <div className="tw-flex tw-flex-wrap tw-text-4xl">
        <Link
          to={{ pathname: `/user/${userId}`, state: user }}
          className={grid + " "}
        >
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-blue-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <FcOrganization className="tw-text-left tw-text-5xl tw-mb-4" />
            My Profile
          </div>
        </Link>

        <Link
          to={{ pathname: "/collaborate", state: user }}
          className={grid + " "}
        >
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-green-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <FcCollaboration className="tw-text-left tw-text-5xl tw-mb-4" />
            Collaborate
          </div>
        </Link>

        <Link
          to={{ pathname: "/communicate", state: user }}
          className={grid + " "}
        >
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-red-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <GrAnnounce
              className="tw-text-left tw-text-5xl tw-mb-4"
              color="blue"
            />
            Communicate
          </div>
        </Link>

        <Link to={`/workshops/${userId}`} className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-purple-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <GrWorkshop className="tw-text-left tw-text-5xl tw-mb-4" />
            Workshops
          </div>
        </Link>

        <Link to={{ pathname: "/topics", state: user }} className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-pink-400 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <FcMindMap className="tw-text-left tw-text-5xl tw-mb-4" />
            Topics
          </div>
        </Link>

        <Link to={{ pathname: "/notes", state: user }} className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-yellow-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <BiNote className="tw-text-left tw-text-5xl tw-mb-4" />
            Notes
          </div>
        </Link>

        <Link
          to={{ pathname: "/poll", state: user, type: "quiz" }}
          className={grid + " "}
        >
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-red-400 tw-text-gray-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <MdAssessment className="tw-text-left tw-text-5xl tw-mb-4" />
            Quiz
          </div>
        </Link>

        <Link
          to={{ pathname: "/poll", state: userId, type: "poll" }}
          className={grid + " "}
        >
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-green-400 tw-text-gray-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <BiPoll className="tw-text-left tw-text-green-100 tw-text-5xl tw-mb-4" />
            Polls
          </div>
        </Link>

        <Link to="" onClick={(e) => logoutUser(e)} className={grid + ""}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-blue-400 tw-text-gray-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <ImExit className="tw-text-left tw-text-5xl tw-mb-4" />
            Logout
          </div>
        </Link>
      </div>
    );
  };

  let homePage = () => {
    return (
      <div className="tw-flex tw-flex-wrap tw-text-4xl">
        <div className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-blue-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <FcOrganization className="tw-text-left tw-text-5xl tw-mb-4" />
            Create
          </div>
        </div>

        <div className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-green-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <FcCollaboration className="tw-text-left tw-text-5xl tw-mb-4" />
            Collaborate
          </div>
        </div>

        <div className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-red-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <GrAnnounce
              className="tw-text-left tw-text-5xl tw-mb-4"
              color="blue"
            />
            Communicate
          </div>
        </div>

        {user == null && (
          <div className={grid + " "}>
            {/* <!-- Column Content --> */}
            <div className="tw-m-3 tw-bg-purple-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
              <GrWorkshop className="tw-text-left tw-text-5xl tw-mb-4" />
              Workshops
            </div>
          </div>
        )}

        <div className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-pink-400 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <FcMindMap className="tw-text-left tw-text-5xl tw-mb-4" />
            Topics
          </div>
        </div>

        <div className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-yellow-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <BiNote className="tw-text-left tw-text-5xl tw-mb-4" />
            Notes
          </div>
        </div>

        <div className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-red-400 tw-text-gray-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <MdAssessment className="tw-text-left tw-text-5xl tw-mb-4" />
            Quiz
          </div>
        </div>

        <div className={grid + " "}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-green-400 tw-text-gray-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <BiPoll className="tw-text-left tw-text-green-100 tw-text-5xl tw-mb-4" />
            Poll
          </div>
        </div>

        <Link to="/login" className={grid + ""}>
          {/* <!-- Column Content --> */}
          <div className="tw-m-3 tw-bg-blue-400 tw-text-gray-100 tw-py-10 tw-rounded-xl tw-shadow-lg">
            <ImEnter className="tw-text-left tw-text-5xl tw-mb-4" />
            Login
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="tw-mt-20">
        {user == null && (
          <div className="tw-m-20 tw-text-8xl tw-text-gray-500 tw-text-center tw-font-semibold">
            Event Central
          </div>
        )}

        {user == null ? homePage() : authHomePage()}
        {/* <div className="col-10 col-md-10 col-lg-8 col-xl-7 tw-text-center">
          <h4
            className="display-4 text-primary mt-3 mb-2"
            style={{
              fontSize: 2.8 + "em",
            }}
          >
            Rail Safety Workshop
          </h4>
          <p className="lead" style={biggerLead}>
            Welcome to Rail Safety Workshop App, this app allows convenors to
            create, review virtual workshops, quizzes and invite members to
            check in, and participate in a Safety Virtual Workshop remotely.
            It's a practical way to conduct workshops during the pandemic times{" "}
            <a href="https://vuejs.org/">React</a> with{" "}
            <a href="https://firebase.google.com">Firebase</a>.
          </p>

          {user == null && (
            <>
              <Link className="btn btn-outline-primary mr-2" to="/register">
                Register
              </Link>
              <Link className="btn btn-outline-primary mr-2" to="/login">
                Log In
              </Link>
            </>
          )}
          {user && (
            <div>
              <Link
                className="btn btn-primary m-2"
                to={{ pathname: "/workshops", state: user }}
              >
                Workshops
              </Link>
              <Link
                className="btn btn-primary m-2"
                to={{ pathname: "/appointments", state: user }}
              >
                Appointments
              </Link>
              <IconButton
                label="Functions"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("Icon Button Clicked");
                  fetch(
                    "/app/get-user-by-email?email=shankar.chari@gmail.com",
                    {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + idToken,
                      },
                    }
                  )
                    .then((res) => {
                      // TODO
                      return res.json();
                    })
                    .then((data) => {
                      // TODO
                      console.log(data);
                    })
                    .catch((error) => {
                      // TODO
                      console.log(error);
                    });
                }}
              />
            </div>
          )}
        </div> */}
      </div>
      {isLoading && <PleaseWait opacity={"100"} />}
    </div>
  );
};

export default Home;
