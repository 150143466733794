// https://codepen.io/lhermann/pen/EBGZRZ
import "./Toggle.css";

const Toggle = ({ label }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-mb-12">
      <label
        htmlFor="toogleA"
        className="tw-flex tw-items-center tw-cursor-pointer"
      >
        <div className="tw-relative">
          <input id="toogleA" type="checkbox" className="tw-sr-only" />

          <div className="tw-w-10 tw-h-4 tw-bg-gray-400 tw-rounded-full tw-shadow-inner"></div>

          <div className="dot tw-absolute tw-w-6 tw-h-6 tw-bg-white tw-rounded-full tw-shadow tw--left-1 tw--top-1 tw-transition"></div>
        </div>

        <div className="tw-ml-3 tw-text-gray-700 tw-font-medium">{label}</div>
      </label>
    </div>
  );
};

export default Toggle;
