import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

const fbConfig = require("../firebase-config.json");

firebase.initializeApp(fbConfig);
//firebase.functions().useEmulator("localhost", 5001);
export const functions = firebase.functions();
//if (location.hostname === "localhost") {
// firebase.functions().useEmulator("localhost", 5001);
//}

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
// auth.useEmulator("http://localhost:9099");

export const firestore = firebase.firestore();
// firestore.useEmulator("localhost", 8080);

export const storage = firebase.storage();

export const ARRAY_UNION = (documentRef, key, value) => {
  let update = {};
  update[key] = firebase.firestore.FieldValue.arrayUnion(value);
  return documentRef.update(update);
};

export const ARRAY_REMOVE = (documentRef, key, value) => {
  let update = {};
  update[key] = firebase.firestore.FieldValue.arrayRemove(value);
  return documentRef.update(update);
};
export const ARRAY_UNION_UNIQUE = (documentRef, item) => {};

export const GET_DOC_BY_IDS = (collectionName, uids) => {
  const topicsRef = firestore.collection(collectionName);
  return topicsRef.where("__name__", "in", uids);
};

/* UPDATES */
export const MERGE_FIELDS = (collectionName, documentId, fields) => {
  const documentRef = firestore.collection(collectionName).doc(documentId);
  return documentRef.set(fields, { merge: true });
};

export const REMOVE_FILE = (reference) => {
  let storageRef = storage.ref(reference);
  return storageRef.delete();
};

export default firebase;
