import { useState } from "react";
import { FcSurvey } from "react-icons/fc";

import Submit from "../../../components/forms/Submit";
import PollStation from "../../quiz/PollStation";
import AddInsights from "./AddInsights";

const MainActivity = ({
  userId,
  workshop,
  topic,
  objective,
  poll,
  quiz,
  type,
  checkInData,
  markActivityComplete,
}) => {
  const [isPollDone, setPollDone] = useState(
    !(poll && poll.options && poll.options.length > 0)
  );
  const [isQuizDone, setQuizDone] = useState(
    !(quiz && quiz.options && quiz.options.length > 0)
  );
  const [isInsightsDone, setInsightsDone] = useState(false);

  const onComplete = (type) => {
    switch (type) {
      case "poll":
        setPollDone(true);
        break;

      case "quiz":
        setQuizDone(true);
        break;

      case "insights":
        setInsightsDone(true);
        break;

      default:
        break;
    }
  };

  // On submit of poll
  //  1. Insert uid in poll doc against choices
  //  2. New collection, Poll responses
  //  2.1. workshopId(i1), uid(i2), "workshop", null, pollResults, quizResults, Insights
  //  2.2. workshopId(i1), uid(i2), "topic", topicId, pollResults, quizResults, Insights
  let itemReference = type === "workshops" ? workshop.id : topic.id;
  return (
    <div className="tw-mt-8">
      {topic && (
        <div className="tw-bg-white tw-mb-5 lg:tw-mb-10">
          <h1 className="tw-text-3xl tw-font-thin tw-capitalize tw-mb-3">
            <span className="tw-flex tw-text-center">
              <FcSurvey size="40" />
              {topic.title}
            </span>
          </h1>
          <div className="tw-mt-4 tw-text-sm">{topic.description}</div>
        </div>
      )}
      <div className="tw-flex tw-justify-equal">
        {objective && (
          <div
            className="tw-bg-white"
            dangerouslySetInnerHTML={{ __html: objective.content }}
          ></div>
        )}
        {checkInData && (
          <div>
            {poll && poll.options && poll.options.length > 0 && (
              <PollStation
                data={poll}
                type={"poll"}
                userId={userId}
                onComplete={onComplete}
                checkInData={checkInData}
              />
            )}
            {quiz && quiz.options && quiz.options.length > 0 && (
              <PollStation
                data={quiz}
                type={"quiz"}
                userId={userId}
                onComplete={onComplete}
                checkInData={checkInData}
              />
            )}
            <AddInsights
              itemReference={itemReference}
              type={type}
              userId={userId}
              onComplete={onComplete}
              checkInData={checkInData}
            />
            {isPollDone && isQuizDone && isInsightsDone && (
              <Submit
                label={"Mark Completed"}
                onClick={() => {
                  markActivityComplete(topic.id);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MainActivity;
