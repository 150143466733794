import React from "react";
import { withRouter } from "react-router-dom"; // import './App.css';
import { AiOutlineUserAdd } from "react-icons/ai";
import { toast } from "react-toastify";

import { firestore, ARRAY_UNION } from "../Firebase";

class CheckIn extends React.Component {
  constructor(props) {
    super(props);

    this.resetState = {
      displayName: "",
      email: "",
      errorMessage: null,
      toggleForm: false,
    };

    this.state = {
      displayName: "",
      email: "",
      errorMessage: null,
      toggleForm: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setToggleForm = this.setToggleForm.bind(this);
  }

  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState({
      [itemName]: itemValue,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setToggleForm(!this.state.toggleForm);

    let checkInInfo = {
      email: this.state.email,
      displayName: this.state.displayName,
    };

    if (this.props.participantIds.includes(checkInInfo.email)) {
      // TODO Response Handler
      // User already exists
      this.setState(this.resetState);
      return;
    }

    let that = this;

    let checkInRef = firestore.collection("workshops");
    let workshopID = this.props.match.params.workshopID;
    let participantIds = that.props.participantIds;

    fetch(`/app/get-user-by-email?email=${checkInInfo.email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("idToken"),
      },
    })
      .then((res) => {
        // TODO
        return res.json();
      })
      .then((data) => {
        // TODO
        checkInInfo.uid = data.uid;
        // toast("Participant successfully added!!!");
        if (!participantIds.includes(checkInInfo.uid)) {
          ARRAY_UNION(checkInRef.doc(workshopID), "participants", checkInInfo)
            .then((results) => {
              // TODO
              that.setState(that.resetState);
              toast("Participant successfully added!!!");
              ARRAY_UNION(
                firestore.collection("users").doc(data.uid),
                "invites",
                workshopID
              ).then((results) => {});
            })
            .catch((error) => {
              // TODO
              toast.error(`Adding Participant Failure: ${error.message}`);
              that.setState(that.resetState);
              console.error("Error", error);
            });
        } else {
          // TODO Response Handler
          // User already exists
          toast.info(`User exists, ${checkInInfo.email}`);
          that.setState(that.resetState);
        }
      })
      .catch((error) => {
        // TODO
        that.setState(that.resetState);
        toast.error(`Adding Participant Failure: ${error.message}`);
      });
  }

  setToggleForm(toggleForm) {
    this.setState({ ...this.state, toggleForm: toggleForm });
  }

  render() {
    return (
      <div className="tw-App tw-container tw-mx-auto tw-mt-3 tw-font-thin">
        <button
          onClick={() => this.setToggleForm(!this.state.toggleForm)}
          className={
            "tw-bg-purple-200 tw-text-white tw-px-2 tw-py-3 tw-w-full tw-text-left " +
            (this.state.toggleForm === true
              ? "tw-rounded-t-md"
              : "tw-rounded-md")
          }
        >
          <div className="tw-text-4xl tw-text-gray-800 tw-font-thin">
            <AiOutlineUserAdd className="tw-inline-block tw-align-text-top" />{" "}
            Add Participant
          </div>
        </button>
        {this.state.toggleForm && (
          <div className="tw-bg-white tw-border-r-2 tw-border-b-2 tw-border-l-2 tw-border-light-blue-500 tw-rounded-b-md tw-pl-4 tw-pr-4 tw-pb-4">
            <form className="" onSubmit={this.handleSubmit}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="">
                    <div className="">
                      <div className="">
                        <section className="form-group">
                          <label
                            className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
                            htmlFor="displayName"
                          >
                            Name
                          </label>
                          <input
                            required
                            className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                            type="text"
                            id="displayName"
                            name="displayName"
                            placeholder="Name"
                            value={this.state.displayName}
                            onChange={this.handleChange}
                          />
                        </section>
                        <section className="form-group">
                          <label
                            className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
                            htmlFor="Email"
                          >
                            Email
                          </label>
                          <input
                            required
                            className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                        </section>
                        <div className="form-group text-end m-3">
                          <button
                            type="submit"
                            className="tw-ml-3 tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-purple-200 hover:tw-bg-purple-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-400"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CheckIn);
