import { createContext } from "react";

export const ProfileContext = createContext([]);

export const ProfileProvider = (props) => {
  return (
    <ProfileContext.Provider value={props.profile}>
      {props.children}
    </ProfileContext.Provider>
  );
};
