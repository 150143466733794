import React from "react";
import { withRouter } from "react-router-dom";

import { firestore } from "../Firebase";
import ParticipantsList from "./ParticipantsList";
import SearchParticipants from "./SearchParticipants";
import CheckIn from "./CheckIn";

class Participants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayParticipants: [],
      allParticipants: [],
      starUsers: [],
      createdBy: null,
      searchQuery: "",
      sortBy: "displayName",
      orderBy: "asc",
      toggle: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.resetQuery = this.resetQuery.bind(this);
    this.chooseRandom = this.chooseRandom.bind(this);
  }

  componentDidMount() {
    let workshopID = this.props.match.params.workshopID;
    let participantsDocRef = firestore.collection("workshops").doc(workshopID);

    let that = this;
    participantsDocRef.onSnapshot((workshopSnapshot) => {
      let data = workshopSnapshot.data();
      let tmpParticipants = data.participants;
      let tmpStarUsers = data.starUsers;

      let createdBy = data.createdBy;

      let participants = [];
      let participantIds = [];
      tmpParticipants.forEach((item) => {
        participants.push({
          displayName: item.displayName,
          email: item.email,
          uid: item.uid,
        });
        participantIds.push(item.uid.length > 0 ? item.uid : item.email);
      });

      let starUsers = [];
      tmpStarUsers.forEach((item) => {
        starUsers.push(item.email);
      });

      that.setState({
        displayParticipants: participants,
        starUsers: starUsers,
        createdBy: createdBy,
        allParticipants: participants,
        participantIds: participantIds,
      });
    });
  }

  handleChange(e) {
    const itemName = e.target.displayName;
    const itemValue = e.target.value;

    this.setState({
      [itemName]: itemValue,
    });
  }

  resetQuery(e) {
    this.setState({
      displayParticipants: this.state.allParticipants,
      searchQuery: "",
    });
  }

  chooseRandom() {
    const randomParticipant = Math.floor(
      Math.random() * this.state.allParticipants.length
    );
    this.resetQuery();
    this.setState({
      displayParticipants: [this.state.allParticipants[randomParticipant]],
    });
  }

  renderParticipants(filteredParticipants) {
    return (
      <div>
        {this.props.mode !== "read" && (
          <CheckIn participantIds={this.state.participantIds} />
        )}
        {filteredParticipants && filteredParticipants.length > 0 && (
          <>
            <SearchParticipants
              query={this.state.searchQuery}
              onQueryChange={(myQuery) =>
                this.setState({ ...this.state, searchQuery: myQuery })
              }
              orderBy={this.state.orderBy}
              onOrderByChange={(myOrderBy) =>
                this.setState({ ...this.state, orderBy: myOrderBy })
              }
              sortBy={this.state.sortBy}
              onSortByChange={(mySortBy) =>
                this.setState({ ...this.state, sortBy: mySortBy })
              }
              toggleAdjust={(toggle) => {
                this.setState({ ...this.state, toggle: toggle });
              }}
            />
            <div className={this.state.toggle ? "tw-mt-28" : "tw-mt-0"}>
              <ParticipantsList
                workshopId={this.props.match.params.workshopID}
                createdBy={this.props.createdBy}
                participants={filteredParticipants}
                starUsers={this.state.starUsers}
                userID={this.state.userID}
                participantIds={this.state.participantIds}
                mode={this.props.mode}
              />
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    const filteredParticipants = this.state.allParticipants
      .filter((item) => {
        return item.displayName
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase());
      })
      .sort((a, b) => {
        let order = this.state.orderBy === "asc" ? 1 : -1;
        return a[this.state.sortBy].toLowerCase() <
          b[this.state.sortBy].toLowerCase()
          ? -1 * order
          : 1 * order;
      });
    return (
      <div className="tw-w-auto tw-px-4">
        <div className="tw-row tw-justify-content-center">
          <h1 className="tw-text-center tw-text-5xl tw-font-thin tw-mb-3">
            Participants
          </h1>
        </div>
        {this.renderParticipants(filteredParticipants)}
      </div>
    );
  }
}

export default withRouter(Participants);
