import { useState } from "react";
import { GoTrashcan } from "react-icons/go";
import { AiOutlineEdit, AiOutlineRead } from "react-icons/ai";
import { BiPoll, BiNotepad } from "react-icons/bi";
import { MdAssessment } from "react-icons/md";

import { toast } from "react-toastify";

import FileUpload from "../../../components/FileUpload";
import IconButton from "../../../components/IconButton";
import ImagePreview from "../../../components/ImagePreview";
import DeleteItem from "../../../components/DeleteItem";
import EditItem from "../../../components/EditItem";
import Quiz from "../../quiz/Quiz";
import RichText from "../../../components/RichText";

import {
  ARRAY_REMOVE,
  firestore,
  MERGE_FIELDS,
  REMOVE_FILE,
} from "../../Firebase";
import { useHistory } from "react-router";

// https://tailwindcomponents.com/component/question-listing-item-card
const TopicInfo = ({ topicId, info, onEdit, workshopId, mode, userId }) => {
  const history = useHistory();
  let [deleteConfirm, showDeleteConfirmation] = useState(false);
  let [editPoll, setEditPoll] = useState({
    isEditPoll: false,
    pollId: null,
    pollData: null,
  });
  let [editNote, setEditNote] = useState({
    isEditNote: false,
    noteId: null,
    noteData: null,
  });

  let onUploadComplete = (snapshot, url, fileType, fullPath, itemRef) => {
    // update download url of the topic: imageUrl
    // If imageUrl present - preview the image
    MERGE_FIELDS("topics", itemRef, {
      file: url,
      fileType: fileType,
      fullPath: fullPath,
    });
  };

  const onCreatePoll = (pollId, topicId, type) => {
    MERGE_FIELDS("topics", topicId, { [type]: pollId })
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        toast.error(
          `Error while inserting poll reference to topic: 
        ${error.message}`
        );
      });
  };

  const onEditPoll = (pollId) => {
    // Load the data and keep it
    // Pass it to quiz
    const pollDocRef = firestore.collection("polls").doc(pollId);

    pollDocRef.onSnapshot((res) => {
      setEditPoll({
        isEditPoll: true,
        pollId: pollId,
        pollData: res.data(),
      });
    });
  };

  const onCompleteEditPoll = (pollId) => {
    setEditPoll({
      isEditPoll: false,
      pollId: null,
      pollData: null,
    });
  };

  const onCreateNote = (noteId, topicId, type) => {
    MERGE_FIELDS("topics", topicId, { note: noteId })
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        toast.error(
          `Error while inserting poll reference to topic: 
        ${error.message}`
        );
      });
  };
  const onEditNote = (noteId) => {
    // Load the data and keep it
    // Pass it to quiz
    const noteDocRef = firestore.collection("notes").doc(noteId);

    noteDocRef.onSnapshot((res) => {
      console.log(res.data());
      setEditNote({
        isEditNote: true,
        noteId: noteId,
        noteData: res.data(),
      });
    });
  };

  const onCompleteEditNote = (noteId) => {
    setEditNote({
      isEditNote: false,
      noteId: null,
      noteData: null,
    });
  };

  const deleteTopic = (topicId) => {
    showDeleteConfirmation(!deleteConfirm);
    let topicsRef = firestore.collection("topics");

    topicsRef
      .doc(topicId)
      .delete()
      .then(() => {
        // TODO
        // console.log("Document successfully deleted!");
        //TODO: Remove from the Workshop: topics
        toast(`Document successfully deleted!`);
        const workshopRef = firestore.collection("workshops").doc(workshopId);
        ARRAY_REMOVE(workshopRef, "topics", topicId)
          .then((_) => {})
          .catch((_) => {
            toast.error(`Error while deleting the participant, ${_.message}`);
          });
      })
      .catch((error) => {
        // TODO
        // console.error("Error deleting document: ", error);
        toast.error(`Error occurred while creating Topic: ${error.message}`);
      });
  };

  const deleteImage = (reference, topicId) => {
    // Delete from storage
    // remove from the topic details
    REMOVE_FILE(reference)
      .then(() => {
        // TODO: Success file remove
        toast(`Image successfully deleted!!!`, {
          progress: 1,
          autoClose: 5000,
        });
        MERGE_FIELDS("topics", topicId, {
          file: "",
          fileType: "",
          fullPath: "",
        });
      })
      .catch((error) => {
        toast.error(
          `Error occurred while deleting the image: ${error.message}`
        );
      });
  };

  const confirmDelete = (e) => {
    e.preventDefault();
    showDeleteConfirmation(!deleteConfirm);
  };

  return (
    <li className="mt-2 tw-p-3 tw-bg-white tw-rounded-lg tw-tracking-wide tw-border tw-border-blue-100">
      <div className="tw-flex">
        {mode !== "read" && info.file === "" && (
          <FileUpload
            label={null}
            itemRef={info.id}
            metaData={{ ref: "topics" }}
            destRef={"topics-images"}
            onComplete={onUploadComplete}
          />
        )}
        {info.file !== "" && (
          <ImagePreview
            itemLabel={"Topic"}
            itemDetail={info.title}
            itemRef={info.id}
            fullPath={info.fullPath}
            url={info.file}
            onDelete={deleteImage}
            mode={mode}
            showPic={true}
          />
        )}
        <div className="tw-mx-4">
          <div className="tw-flex tw-items-center">
            <span className="tw-text-2xl tw-font-normal tw-capitalize">
              {info.title}
            </span>
            <span className="tw-text-right">{info.type}</span>
          </div>
          <div className="tw-text-xl">{info.description}</div>
          <div className="tw-leading-tight tw-text-blue-500">
            <b className="tw-font-bold">Insights: </b>
            <i>{info.insights}</i>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-wrap tw-px-24">
        {mode === "write" && (
          <IconButton
            icon={mode === "read" ? <AiOutlineRead /> : <AiOutlineEdit />}
            title={"Update Topic Details"}
            onClick={(e) => {
              e.preventDefault();
              onEdit(info);
            }}
          />
        )}
        {(mode === "write" || (info.notes && info.notes.length > 0)) && (
          <IconButton
            icon={<BiNotepad />}
            title={"Add Rich Content"}
            onClick={(e) => {
              e.preventDefault();
              if (!info.note) {
                history.push({
                  pathname: `/notes/${info.id}`,
                  state: userId,
                  reference: info.id,
                  size: "md",
                  onComplete: onCreateNote,
                });
              } else {
                onEditNote(info.note);
              }
            }}
          />
        )}

        {(mode === "write" || (info.poll && info.poll.length > 0)) && (
          <IconButton
            icon={<BiPoll />}
            title={"Attach a Poll to the Event"}
            onClick={(e) => {
              e.preventDefault();
              if (!info.poll) {
                history.push({
                  pathname: `/poll/${info.id}`,
                  state: userId,
                  reference: info.id,
                  type: "poll",
                  onComplete: onCreatePoll,
                });
              } else {
                onEditPoll(info.poll);
              }
            }}
          />
        )}
        {(mode === "write" || (info.quiz && info.quiz.length > 0)) && (
          <IconButton
            icon={<MdAssessment />}
            title={"Attach a Quiz to the Event"}
            onClick={(e) => {
              e.preventDefault();

              if (!info.quiz) {
                history.push({
                  pathname: `/poll/${info.id}`,
                  state: userId,
                  reference: info.id,
                  type: "quiz",
                  onComplete: onCreatePoll,
                });
              } else {
                onEditPoll(info.quiz);
              }
            }}
          />
        )}

        {mode !== "read" && (
          <button
            className="btn btn-sm"
            title="Delete Participant"
            onClick={confirmDelete}
          >
            <GoTrashcan />
          </button>
        )}
        {deleteConfirm && (
          <DeleteItem
            onDelete={deleteTopic}
            onCancel={confirmDelete}
            itemLabel={"topic"}
            itemReference={info.id}
          />
        )}
        {editPoll.isEditPoll && (
          <EditItem
            content={
              <Quiz
                isEdit={editPoll.isEditPoll}
                pollId={editPoll.pollId}
                userId={userId}
                data={editPoll.pollData}
                onComplete={onCompleteEditPoll}
              />
            }
            onCancel={(e) => {
              e.preventDefault();
              onCompleteEditPoll();
            }}
          />
        )}
        {editNote.isEditNote && (
          <EditItem
            size="lg"
            content={
              <RichText
                // createdBy={createdBy}
                isEdit={editNote.isEditNote}
                notesId={editNote.noteId}
                userId={userId}
                data={editNote.noteData}
                onComplete={onCompleteEditNote}
                size="lg"
              />
            }
            onCancel={(e) => {
              e.preventDefault();
              onCompleteEditNote();
            }}
          />
        )}
      </div>
    </li>
  );
};

export default TopicInfo;
