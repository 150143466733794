import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { firestore } from "../modules/Firebase";

const Avatar = ({ isOnline, size, userId }) => {
  const [profile, setProfile] = useState(null);

  const fetchProfile = useCallback(() => {
    let userRef = firestore.collection("users").doc(userId);
    userRef.onSnapshot((response) => {
      // console.log(response.data());
      let data = response.data();
      setProfile(data);
    });
  }, [userId]);

  useEffect(() => {
    fetchProfile();
  }, [userId, fetchProfile]);

  const online = isOnline ? "tw-bg-green-300 " : "tw-bg-white ";
  let imageLoc =
    "https://i1.pngguru.com/preview/137/834/449/cartoon-cartoon-character-avatar-drawing-film-ecommerce-facial-expression-png-clipart.jpg";
  imageLoc = profile && profile.file.length > 0 ? profile.file : imageLoc;

  let avatarSize =
    "tw-p-4 tw-py-6 tw-w-28 tw-py-1 tw-border tw-border-blue-300 tw-rounded tw-bg-white tw-rounded-lg tw-shadow-lg ";
  let textSize = "tw-text-4xl ";
  if (size === "sm") {
    avatarSize = "tw-w-12 ";
    textSize = "tw-text-xl ";
  }

  let content = (
    <div className="tw-flex tw-justify-between ">
      {profile && profile.displayName.length > 0 && (
        <div className={textSize + "tw-px-3 tw-font-normal"}>
          {profile.displayName}
          <div className="tw-flex tw-items-center tw-text-xs tw-uppercase tw-font-semibold">
            {profile.title}
          </div>
        </div>
      )}
      <div className="tw-mb-2">
        <div className={online + avatarSize + "tw-text-center tw-text-white"}>
          <div className="">
            <img
              className="tw-w-auto tw-mx-auto tw-rounded-full tw-object-cover tw-object-center"
              src={imageLoc}
              alt="Avatar Upload"
            />
          </div>
        </div>
      </div>
    </div>
  );
  if (size === "xs") {
    avatarSize = "tw-w-12 ";
    textSize = "tw-text-xl ";
    content = (
      <div className="tw-mb-2">
        <div className={online + avatarSize + "tw-text-center tw-text-white"}>
          <div className="">
            <img
              className="tw-w-auto tw-mx-auto tw-rounded-full tw-object-cover tw-object-center"
              src={imageLoc}
              alt="Avatar Upload"
            />
          </div>
        </div>
      </div>
    );
  }
  return <Link to={`/user/${userId}`}>{content}</Link>;
};

export default Avatar;
