import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MERGE_FIELDS, REMOVE_FILE } from "../Firebase";
import { AiOutlineEdit } from "react-icons/ai";
import { format } from "date-fns";

import IconButton from "../../components/IconButton";
import FileUpload from "../../components/FileUpload";
import ImagePreview from "../../components/ImagePreview";
import EditItem from "../../components/EditItem";
import UpdateProfile from "./UpdateProfile";

const ProfileCard = ({ userId, profile, profilec, mode }) => {
  const [editProfile, setEditProfile] = useState(false);

  let onUploadComplete = (snapshot, url, fileType, fullPath, itemRef) => {
    // update download url of the topic: imageUrl
    // If imageUrl present - preview the image
    MERGE_FIELDS("users", itemRef, {
      file: url,
      fileType: fileType,
      fullPath: fullPath,
    });
  };

  const deleteImage = (reference, userId) => {
    // Delete from storage
    // remove from the topic details
    REMOVE_FILE(reference)
      .then(() => {
        // TODO: Success file remove
        toast(`Image successfully deleted!!!`, {
          progress: 1,
          autoClose: 5000,
        });
        MERGE_FIELDS("users", userId, {
          file: "",
          fileType: "",
          fullPath: "",
        });
        profile = { ...profile, file: "", fileType: "", fullPath: "" };
      })
      .catch((error) => {
        toast.error(
          `Error occurred while deleting the image: ${error.message}`
        );
      });
  };

  const onUpdateProfile = () => {
    setEditProfile(false);
  };
  return (
    <div className="tw-bg-white p-3 tw-border-t-4 tw-border-blue-100">
      <div className="tw-image tw-overflow-hidden">
        {profile.file.length > 0 && (
          <img
            className="tw-h-auto tw-w-full tw-mx-auto"
            src={profile.file}
            alt=""
          />
        )}
        <div className="tw-flex">
          {mode !== "read" && profile.file === "" && (
            <FileUpload
              label={null}
              itemRef={profilec.uid}
              metaData={{ ref: "profiles" }}
              destRef={"profile-images"}
              onComplete={onUploadComplete}
            />
          )}
          {profile.file !== "" && (
            <ImagePreview
              itemLabel={profile.displayName}
              itemDetail={profile.description}
              itemRef={profilec.uid}
              fullPath={profile.fullPath}
              url={profile.file}
              onDelete={deleteImage}
              mode={mode}
              showPic={false}
            />
          )}
        </div>
      </div>
      <h1 className="tw-text-gray-900 tw-font-thin tw-text-3xl tw-leading-8 tw-my-4">
        {profile.displayName}
      </h1>
      <div className="tw-flex tw-items-center">
        <span className="tw-text-gray-600 tw-font-normal tw-uppercase tw-leading-6">
          {profile.title}
          <p className="text-sm tw-font-xs tw-font-thin tw-lowercase tw-text-blue-500 hover:tw-text-blue-700 tw-leading-6">
            {profile.email}
          </p>
        </span>
        <span className="tw-ml-auto tw-text-blue-500">
          {/* <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm"> */}
          <IconButton
            icon={<AiOutlineEdit />}
            title={"Update Your Profile"}
            onClick={(e) => {
              e.preventDefault();
              setEditProfile(true);
            }}
          />
          {/* </span> */}
        </span>
      </div>
      <p className="text-sm tw-text-gray-500 hover:tw-text-gray-600 tw-leading-6">
        {profile.description}
      </p>
      <ul className="tw-bg-gray-100 tw-text-gray-600 hover:tw-text-gray-700 hover:tw-shadow tw-py-2 tw-px-3 tw-mt-3 tw-divide-y tw-rounded tw-shadow-sm">
        <li className="tw-flex tw-items-center tw-py-3">
          <span>Status</span>
          <span className="tw-ml-auto">
            <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
              Online
            </span>
          </span>
        </li>
        <li className="tw-flex tw-items-center tw-py-3">
          <span>Member since</span>
          <span className="tw-ml-auto">
            <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
              {format(
                new Date(profilec.metadata.creationTime),
                "MMMM do, yyyy"
              )}
            </span>
          </span>
        </li>
        <li className="tw-flex tw-items-center tw-py-3">
          <span>Polls and Quizzes</span>
          <span className="tw-ml-auto">
            <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
              {profile.polls.length}
            </span>
          </span>
        </li>
        <Link to={`/workshops/${profilec.uid}`}>
          <li className="tw-flex tw-items-center tw-py-3">
            <span>Workshops</span>
            <span className="tw-ml-auto">
              <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
                {profile.workshops.length}
              </span>
            </span>
          </li>
        </Link>
        <li className="tw-flex tw-items-center tw-py-3">
          <span>Reviews</span>
          <span className="tw-ml-auto">
            <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
              {profile.reviews.length}
            </span>
          </span>
        </li>
        <li className="tw-flex tw-items-center tw-py-3">
          <span>Invites</span>
          <span className="tw-ml-auto">
            <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
              {profile.invites.length}
            </span>
          </span>
        </li>
        <li className="tw-flex tw-items-center tw-py-3">
          <span>Topics</span>
          <span className="tw-ml-auto">
            <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
              {profile.polls.length}
            </span>
          </span>
        </li>
        <li className="tw-flex tw-items-center tw-py-3">
          <span>Notes</span>
          <span className="tw-ml-auto">
            <span className="tw-bg-blue-400 tw-py-1 tw-px-2 tw-rounded tw-text-white tw-text-sm">
              {profile.notes.length}
            </span>
          </span>
        </li>
      </ul>
      {editProfile && (
        <EditItem
          content={
            <UpdateProfile
              userId={profilec.uid}
              data={profile}
              onComplete={onUpdateProfile}
            />
          }
          onCancel={(e) => {
            e.preventDefault();
            setEditProfile(false);
          }}
        />
      )}
    </div>
  );
};

export default ProfileCard;
