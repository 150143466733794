// https://tailwindcomponents.com/component/calendar-date

const DateTime = ({ date, time }) => {
  const timeSplits = time.split(":");
  let hour = Number(timeSplits[0]);
  const mins = timeSplits[1];

  let ampm = "AM";
  if (hour > 12) {
    hour = hour - 12;
    ampm = "PM";
  }

  let dateObj = new Date(date);
  let weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="tw-rounded-t tw-overflow-hidden tw-bg-white tw-text-center tw-w-24 tw-text-sm">
      <div className="tw-bg-blue-300 tw-text-white tw-py-1">
        {months[dateObj.getMonth()]}
      </div>
      <div className="tw-pt-1 tw-border-l tw-border-r">
        <span className="tw-text-4xl tw-font-bold">{dateObj.getDate()}</span>
      </div>
      <div className="tw-pb-2 tw-px-2 tw-border-l tw-border-r tw-border-b tw-flex tw-justify-between">
        <span className="tw-text-xs tw-font-bold">
          {weekdays[dateObj.getDay()]}
        </span>
        <span className="tw-text-xs tw-font-bold">
          {dateObj.getUTCFullYear()}
        </span>
      </div>
      <div className="tw-bg-blue-300 tw-text-white tw-py-1 tw-rounded-b ">
        {hour}:{mins} {ampm}
      </div>
    </div>
  );
};

export default DateTime;
