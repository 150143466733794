import "./PleaseWait.css";

// https://tailwindcomponents.com/component/animated-ellipsis
const PleaseWait = ({ opacity }) => {
  let opaque = "tw-opacity-" + opacity;
  return (
    <div
      className={
        "tw-min-w-screen tw-h-screen tw-animated tw-fadeIn tw-faster  tw-fixed  tw-left-0 tw-top-0 tw-flex tw-justify-center tw-items-center tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none tw-bg-no-repeat tw-bg-center tw-bg-cover tw-bg-white " +
        opaque
      }
      id="modal-id"
    >
      <div className="tw-fixed tw-top-0 tw-left-0 tw-z-50 tw-w-screen tw-h-screen tw-flex tw-items-center tw-justify-center">
        <div className="tw-bg-white tw-border tw-py-2 tw-px-5 tw-rounded-lg tw-flex tw-items-center tw-flex-col">
          <div className="loader-dots tw-block tw-relative tw-w-20 tw-h-5 tw-mt-2">
            <div className="tw-absolute tw-top-0 tw-mt-1 tw-w-3 tw-h-3 tw-rounded-full tw-bg-yellow-500"></div>
            <div className="tw-absolute tw-top-0 tw-mt-1 tw-w-3 tw-h-3 tw-rounded-full tw-bg-yellow-500"></div>
            <div className="tw-absolute tw-top-0 tw-mt-1 tw-w-3 tw-h-3 tw-rounded-full tw-bg-yellow-500"></div>
            <div className="tw-absolute tw-top-0 tw-mt-1 tw-w-3 tw-h-3 tw-rounded-full tw-bg-yellow-500"></div>
          </div>
          <div className="tw-text-gray-500 tw-text-xs tw-font-light tw-mt-2 tw-text-center">
            Please wait...
          </div>
        </div>
      </div>
    </div>
  );
};

export default PleaseWait;
