import { useCallback, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { firestore, GET_DOC_BY_IDS } from "../../Firebase";
import { FcHome } from "react-icons/fc";
import { BiCalendarPlus } from "react-icons/bi";
import Avatar from "../../../components/Avatar";
import DateTime from "../../../components/DateTime";
import PleaseWait from "../../../components/PleaseWait";
import MainActivity from "./MainActivity";
import FriendsCard from "../../user/FriendsCard";
import ActivitiesTimeline from "./ActivitiesTimeline";
import IconButton from "../../../components/IconButton";

const Venue = () => {
  const { workshopId, checkInId } = useParams();
  let profilec = useContext(ProfileContext);
  const [workshop, setWorkshop] = useState(null);
  const [checkInData, setCheckInData] = useState(null);
  const [topics, setTopics] = useState(null);
  const [objectiveId, setObjectiveId] = useState(null);
  const [objective, setObjective] = useState(null);
  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [quizzes, setQuizzes] = useState(null);
  const [polls, setPolls] = useState(null);
  const [notes, setNotes] = useState(null);

  const fetchPollsNotesAndQuizzes = (workshop, topics) => {
    let pollIds = [];
    if (workshop.poll && workshop.poll.length > 0) {
      pollIds.push(workshop.poll);
    }
    let quizIds = [];
    if (workshop.quiz && workshop.quiz.length > 0) {
      quizIds.push(workshop.quiz);
    }
    let noteIds = [];
    if (workshop.objective && workshop.objective.length > 0) {
      noteIds.push(workshop.objective);
    }
    topics.forEach((item) => {
      if (item.poll && item.poll.length > 0) {
        pollIds.push(item.poll);
      }

      if (item.quiz && item.quiz.length > 0) {
        quizIds.push(item.quiz);
      }

      if (item.note && item.note.length > 0) {
        noteIds.push(item.note);
      }
    });

    if (checkInId !== "preview" && pollIds.length > 0) {
      GET_DOC_BY_IDS("polls", pollIds).onSnapshot((response) => {
        let tmpPolls = [];
        response.forEach((doc) => {
          let data = doc.data();
          tmpPolls.push({ ...data, id: doc.id });
        });
        setPolls(tmpPolls);
      });
    }

    if (checkInId !== "preview" && quizIds.length > 0) {
      GET_DOC_BY_IDS("polls", quizIds).onSnapshot((response) => {
        let tmpQuizzes = [];
        response.forEach((doc) => {
          let data = doc.data();
          tmpQuizzes.push({ ...data, id: doc.id });
        });
        setQuizzes(tmpQuizzes);
      });
    }

    if (noteIds.length > 0) {
      GET_DOC_BY_IDS("notes", noteIds).onSnapshot((response) => {
        let tmpNotes = [];
        response.forEach((doc) => {
          let data = doc.data();
          tmpNotes.push({ ...data, id: doc.id });
        });
        setNotes(tmpNotes);
      });
    }
  };

  const fetchTopics = (workshop) => {
    const topicIds = workshop.topics;
    if (topicIds.length > 0) {
      GET_DOC_BY_IDS("topics", topicIds).onSnapshot((response) => {
        let tmpTopics = [];
        response.forEach((doc) => {
          let data = doc.data();
          tmpTopics.push({ ...data, id: doc.id });
        });
        setTopics(tmpTopics);

        fetchPollsNotesAndQuizzes(workshop, tmpTopics);
      });
    }
  };

  const fetchWorkshop = useCallback(() => {
    let workshopRef = firestore.collection("workshops").doc(workshopId);
    workshopRef.onSnapshot((response) => {
      let data = { ...response.data(), id: workshopId };
      setWorkshop(data);
      setObjectiveId(data.objective);

      if (data && data.topics) {
        fetchTopics(data);
      }
    });
  }, [workshopId]);

  useEffect(() => {
    fetchWorkshop();
  }, [workshopId, fetchWorkshop]);

  const fetchCheckInData = useCallback(() => {
    let checkInRef = firestore.collection("checkIns").doc(checkInId);
    checkInRef.onSnapshot((response) => {
      let data = { ...response.data(), id: checkInId };
      setCheckInData(data);
    });
  }, [checkInId]);

  useEffect(() => {
    if (checkInId !== "preview") {
      fetchCheckInData();
    }
  }, [checkInId, fetchCheckInData]);

  const fetchObjectives = useCallback(() => {
    let objectiveRef = firestore.collection("notes").doc(objectiveId);
    objectiveRef.onSnapshot((response) => {
      let data = response.data();
      setObjective(data);

      // if (data && data.topics) {
      //   fetchTopics(data.topics);
      // }
    });
  }, [objectiveId]);

  useEffect(() => {
    if (objectiveId !== null && objectiveId.length > 0) {
      fetchObjectives();
    }
  }, [objectiveId, fetchObjectives]);

  // TODO: This method has to be rewritten.
  const onSelectActivity = (type, topicId, itemId) => {
    setSelectedTopicId(topicId);

    // Get topic info
    let topic = null;
    if (topics) {
      let topicList = topics.filter((topic) => topic.id === topicId);

      if (topicList.length > 0) {
        topic = topicList[0];
        setSelectedTopic(topic);
      }

      // Get selected notes
      if (notes) {
        let noteList = notes.filter((note) => note.id === topic.note);
        if (noteList.length > 0) {
          setSelectedNote(noteList[0]);
        }
      }

      if (checkInId !== "preview") {
        // Get quiz info
        let quiz = quizzes.filter((quiz) => quiz.id === topic.quiz)[0];
        setSelectedQuiz(quiz);
        // Get poll info
        let poll = polls.filter((poll) => poll.id === topic.poll)[0];

        setSelectedPoll(poll);
      }
    }
  };

  // Load Workshop
  // Load Topics
  // Calc workshop activity summary
  // Calc workshop activity progress
  //
  // Load Quiz on Click
  //

  const liveEvent = () => {
    return workshop && topics && polls && quizzes && checkInData && notes ? (
      <div className="tw-mx-auto tw-m-0 lg:tw-px-10">
        <div className="md:tw-flex tw-justify-between md:tw--mx-2 ">
          <div className="tw-w-full md:tw-w-1/4 md:tw-mx-2">
            <ActivitiesTimeline
              workshop={workshop}
              topics={topics}
              onClick={onSelectActivity}
              checkInData={checkInData}
              resetTopicId={selectedTopicId}
            />
          </div>
          <div className="tw-w-full md:tw-w-2/4 md:tw-mx-2">
            <div className="lg:tw-order-1 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-2 tw-mb-5 lg:tw-mb-0">
              <div className="tw-flex tw-mt-4 tw-justify-between tw-px-3">
                <div>
                  <Avatar
                    isOnline={false}
                    userId={workshop.createdBy}
                    size={"sm"}
                  />
                  <IconButton
                    icon={<FcHome />}
                    title={"Navigate to Event Activities"}
                    onClick={(e) => {
                      e.preventDefault();
                      let tmpPolls = polls.filter(
                        (poll) => poll.id === workshop.poll
                      );
                      let tmpQuizzes = quizzes.filter(
                        (quiz) => quiz.id === workshop.quiz
                      );
                      let tmpNotes = notes.filter(
                        (note) => note.id === workshop.objective
                      );
                      if (tmpPolls && tmpPolls.length > 0) {
                        setSelectedPoll(tmpPolls[0]);
                      }
                      if (tmpQuizzes && tmpQuizzes.length > 0) {
                        setSelectedQuiz(tmpQuizzes[0]);
                      }
                      if (tmpNotes && tmpNotes.length > 0) {
                        setObjective(tmpNotes[0]);
                      }
                      setSelectedTopicId(null);
                      setSelectedTopic(null);
                    }}
                  />
                </div>
                {/* <div className="tw-text-center tw-text-4xl tw-font-thin">
                  1/5 Activities Completed
                  <div className="tw-text-center tw-text-sm tw-font-semibold tw-uppercase">
                    Your Progress
                  </div>
                </div> */}

                {workshop.date && workshop.time && (
                  <DateTime
                    date={workshop.date}
                    time={workshop.time}
                    timeZone={workshop.timeZone}
                    addAppointment={<BiCalendarPlus />}
                  />
                )}
              </div>
              <div className="tw-mt-4 tw-justify-between tw-px-3">
                {selectedTopic && (
                  <>
                    <MainActivity
                      userId={profilec.uid}
                      workshop={null}
                      topic={selectedTopic}
                      objective={selectedNote}
                      poll={selectedPoll}
                      quiz={selectedQuiz}
                      type="topics"
                      checkInData={checkInData}
                    />
                  </>
                )}
                {!selectedTopic && (
                  <MainActivity
                    userId={profilec.uid}
                    workshop={workshop}
                    topic={selectedTopic}
                    objective={objective}
                    poll={selectedPoll}
                    quiz={selectedQuiz}
                    type="workshops"
                    checkInData={checkInData}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="tw-w-full md:tw-w-1/4 md:tw-mx-2">
            <FriendsCard label={"Participants"} friends={workshop.checkIns} />
          </div>
        </div>
      </div>
    ) : (
      <PleaseWait />
    );
  };

  const previewEvent = () => {
    return workshop && topics ? (
      <div className="tw-mx-auto tw-m-0 lg:tw-px-10">
        <div className="md:tw-flex tw-justify-between md:tw--mx-2 ">
          <div className="tw-w-full md:tw-w-1/4 md:tw-mx-2">
            <ActivitiesTimeline
              workshop={workshop}
              topics={topics}
              onClick={onSelectActivity}
              checkInData={null}
              resetTopicId={selectedTopicId}
            />
          </div>
          <div className="tw-w-full md:tw-w-2/4 md:tw-mx-2">
            <div className="lg:tw-order-1 lg:tw-row-span-1 2xl:tw-row-span-1 lg:tw-col-span-2 tw-mb-5 lg:tw-mb-0">
              <div className="tw-flex tw-mt-4 tw-justify-between tw-px-3">
                <Avatar
                  isOnline={false}
                  userId={workshop.createdBy}
                  size={"sm"}
                />
                <div className="tw-text-center tw-text-4xl tw-font-thin">
                  Event Preview
                  <div className="tw-text-center tw-text-sm tw-font-semibold tw-uppercase">
                    {/* Event Yet To Commence */}
                  </div>
                </div>

                {workshop.data && workshop.time && (
                  <DateTime
                    date={workshop.date}
                    time={workshop.time}
                    timeZone={workshop.timeZone}
                    addAppointment={<BiCalendarPlus />}
                  />
                )}
              </div>
              <IconButton
                icon={<FcHome />}
                title={"Navigate to Event Activities"}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTopicId(null);
                  setSelectedTopic(null);
                }}
              />
              <div className="tw-flex tw-justify-between tw-px-3">
                {selectedTopic && (
                  <MainActivity
                    userId={profilec.uid}
                    workshop={workshop}
                    topic={selectedTopic}
                    objective={selectedNote}
                    poll={null}
                    quiz={null}
                    type="topics"
                    checkInData={null}
                  />
                )}
                {!selectedTopic && (
                  <MainActivity
                    userId={profilec.uid}
                    workshop={workshop}
                    topic={null}
                    objective={objective}
                    poll={null}
                    quiz={null}
                    type="workshops"
                    checkInData={null}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="tw-w-full md:tw-w-1/4 md:tw-mx-2">
            <FriendsCard label={"Participants"} friends={workshop.checkIns} />
          </div>
        </div>
      </div>
    ) : (
      <PleaseWait />
    );
  };

  return checkInId !== "preview" ? liveEvent() : previewEvent();
};

export default Venue;
