import { firestore } from "../modules/Firebase";

class CheckIn {
  constructor(userId, parentId, parentType) {
    this.model = {
      createdBy: userId,
      parentId: parentId,
      parentType: parentType,
      childId: "",
      childType: "",
      pollResults: [],
      quizResults: [],
      insightsResults: [],
      insights: {},
      createdOn: "",
      isCheckedIn: false,
    };

    this.getModel = function () {
      return this.model;
    };

    this.create = function (collectionName) {
      let collectionRef = firestore.collection(collectionName);
      let results = collectionRef.add({
        ...this.model,
        isCheckedIn: true,
      });
      return results;
    };
  }
}

export default CheckIn;
