import { IoIosClose } from "react-icons/io";

const EditItem = ({ itemLabel, itemReference, content, onCancel, size }) => {
  let max_width = "tw-w-full tw-max-w-3xl ";
  if (size && size === "lg") max_width = "tw-w-full tw-max-w-6xl ";
  return (
    <div
      className="tw-min-w-screen tw-h-full tw-overflow-y-scroll  tw-animated tw-fadeIn tw-faster  tw-fixed  tw-left-0 tw-top-0 tw-flex tw-justify-center tw-items-center tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none tw-bg-no-repeat tw-bg-center tw-bg-cover"
      id={"modal-id_" + itemReference}
    >
      <div className="overflow-x-scroll tw-absolute tw-bg-black tw-opacity-30 tw-inset-0 tw-z-0"></div>
      <div
        className={
          max_width +
          "tw-p-5 tw-relative tw-mx-auto tw-my-auto tw-rounded-xl tw-shadow-lg  tw-bg-blue-100"
        }
      >
        <div className="tw-text-5xl tw-text-end">
          <IoIosClose onClick={onCancel} />
        </div>
        {content}
      </div>
    </div>
  );
};

export default EditItem;
