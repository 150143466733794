import { useCallback, useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { MdEvent } from "react-icons/md";
import { parseISO, getUnixTime } from "date-fns";

import PleaseWait from "../../components/PleaseWait";
import Shares from "./Shares";
import {
  ARRAY_UNION,
  firestore,
  GET_DOC_BY_IDS,
  MERGE_FIELDS,
} from "../Firebase";
import { ProfileContext } from "../../contexts/ProfileContext";
import ProfileCard from "./ProfileCard";
import FriendsCard from "./FriendsCard";
import RecentActivities from "./RecentActivities";
import WorkshopList from "../workshops/WorkshopList";
import CheckIn from "../../models/CheckIn";

const Profile = ({ mode }) => {
  let profilec = useContext(ProfileContext);
  let history = useHistory();
  let { userId } = useParams();

  const [profile, setProfile] = useState(null);
  const [invites, setInvites] = useState([]);
  const [inviteCount, setInviteCount] = useState(0);

  const [next, setNext] = useState(null);
  const [past, setPast] = useState(null);
  const [future, setFuture] = useState(null);

  const fetchProfile = useCallback(() => {
    let userRef = firestore.collection("users").doc(userId);
    userRef.onSnapshot((response) => {
      // console.log(response.data());
      setProfile(response.data());
    });
  }, [userId]);

  useEffect(() => {
    fetchProfile();
  }, [userId, fetchProfile]);

  const fetchInvites = useCallback((userId) => {
    let userDoc = firestore.collection("users").doc(userId);
    userDoc.onSnapshot((userSnapshot) => {
      let workshopIds = userSnapshot.data().invites;
      if (workshopIds.length > 0) {
        GET_DOC_BY_IDS("workshops", workshopIds)
          //.get({ name: true, date: true, time: true, uid: true })
          .onSnapshot((res) => {
            let workshops = [];
            let inviters = [];
            res.forEach((doc) => {
              let data = doc.data();
              let timestamp = 0;
              if (data.date !== undefined && data.date.length > 0) {
                timestamp = getUnixTime(
                  parseISO(data.date + "T" + data.time + ":00")
                );
              }
              workshops.push({ ...data, id: doc.id, timestamp: timestamp });
              inviters.push(data.createdBy);
            });
            setInviteCount(workshopIds.length);
            setInvites(workshops);
            let now = Math.round(+new Date() / 1000);
            let tmpFuture = workshops.filter((item) => item.timestamp > now);
            if (tmpFuture && tmpFuture.length > 0) {
              let tmpNext = [];
              tmpNext.push(tmpFuture[0]);
              setNext(tmpNext);
              setFuture(tmpFuture);
            }

            let tmpPast = workshops.filter((item) => item.timestamp < now);
            if (tmpPast && tmpPast.length > 0) {
              setPast(tmpPast);
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    if (profilec.uid === userId) fetchInvites(userId);
  }, [userId, fetchInvites]);

  const launchEvent = (workshopId, ownerId, isCheckedIn, checkIns) => {
    // if owner and userId are same launch - update an attribute in the doc
    // else checkIn - add to checkins list in workshop doc with timestamp
    // checkIn

    if (isCheckedIn) {
      let checkInData = checkIns.filter((checkIn) => checkIn.uid === userId);
      history.push(
        `/virtual-venue/${workshopId}/check-in/${checkInData[0].checkInId}`
      );
    } else {
      if (ownerId === userId) {
        // update isLaunched
        MERGE_FIELDS("workshops", workshopId, {
          isLaunched: true,
        });
      }
      let checkIn = new CheckIn(userId, workshopId, "workshop");
      checkIn.create("checkIns").then((results) => {
        let workshopRef = firestore.collection("workshops").doc(workshopId);
        ARRAY_UNION(workshopRef, "checkIns", {
          uid: userId,
          checkInId: results.id,
          // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
          .then((response) => {
            // Go to event playground
            toast(`Successfully checked in to the workshop.`);
            history.push(`/virtual-venue/${workshopId}/check-in/${results.id}`);
          })
          .catch((error) => {
            toast.error(
              `Error occurred while checking in to the workshop: ${error.message}`
            );
          });
      });
    }
  };

  return (
    <div className="">
      {profile && (
        <div className="tw-mx-auto tw-m-0 lg:tw-px-10">
          <div className="md:tw-flex md:tw--mx-2 ">
            <div className="tw-w-full md:tw-w-350 md:tw-mx-2">
              <ProfileCard profile={profile} profilec={profilec} mode={mode} />
              <div className="tw-my-4"></div>
              <FriendsCard friends={null} />
            </div>
            <div className="tw-w-full tw-mx-5 tw-h-64">
              {profilec.uid === userId && (
                <div>
                  <div className="tw-m-4 tw-border-0 tw-rounded-2">
                    {next && next.length > 0 ? (
                      <h4 className="tw-flex tw-text-5xl font-weight-light tw-mt-8">
                        <MdEvent color={"#93C5FD"} />
                        <span>Next Event</span>
                      </h4>
                    ) : null}
                    {next && next.length > 0 && (
                      <div className="list-group list-group-flush">
                        <WorkshopList
                          workshops={next}
                          showToolbar={true}
                          userId={userId}
                          mode={"read"}
                          launchEvent={launchEvent}
                        />
                      </div>
                    )}
                  </div>
                  <div className="tw-my-4"></div>
                  <RecentActivities past={past} next={future} userId={userId} />
                  <div className="tw-my-4"></div>
                </div>
              )}
              <div className="tw-my-4">
                <Shares />
              </div>
            </div>
          </div>
        </div>
      )}
      {!profile && <PleaseWait />}
    </div>
  );
};

export default Profile;
