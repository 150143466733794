import { BiTrash } from "react-icons/bi";

const AppointmentInfo = ({ info, onDeleteAppointment }) => (
  <li className="tw-px-3 tw-py-3 tw-flex tw-items-start">
    <button
      type="button"
      onClick={() => onDeleteAppointment(info.id)}
      className="tw-p-1.5 tw-mr-1.5 tw-mt-1 tw-rounded tw-text-white tw-bg-red-500 tw-hover:tw-bg-yellow-700 tw-focus:tw-outline-none tw-focus:tw-ring-2 tw-focus:tw-ring-offset-2 tw-focus:tw-ring-blue-500"
    >
      <BiTrash />
    </button>
    <div className="flex-grow">
      <div className="tw-flex tw-items-center">
        <span className="tw-flex-none tw-font-medium tw-text-2xl tw-text-blue-500">
          {info.petName}
        </span>
        <span className="tw-flex-grow tw-text-right">{info.aptDate}</span>
      </div>
      <div>
        <b className="tw-font-bold tw-text-blue-500">Convenor:</b>{" "}
        {info.ownerName}
      </div>
      <div className="tw-leading-tight">{info.aptNotes}</div>
    </div>
  </li>
);

export default AppointmentInfo;
