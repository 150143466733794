import React from "react";
import { Switch, Route, withRouter } from "react-router-dom"; // import './App.css';
import { ToastContainer, Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import firebase, { auth } from "./modules/Firebase";

import Home from "./components/Home";
import Welcome from "./components/Welcome";
import Workshops from "./modules/workshops/Workshops";
import Register from "./modules/auth/Register";
import Login from "./modules/auth/Login";
import CheckIn from "./modules/workshops/CheckIn";
import Participants from "./modules/workshops/Participants";
import Appointments from "./modules/appointments/Appointments";
import AddAppointment from "./modules/appointments/AddAppointment";
import Workshop from "./modules/workshops/Workshop";
import Profile from "./modules/user/Profile";
import Nav from "./components/Nav";

import Create from "./modules/create/Create";
import Collaborate from "./modules/collaborate/Collaborate";
import Communicate from "./modules/communicate/Communicate";
import Discuss from "./modules/discuss/Discuss";
import Notes from "./modules/notes/Notes";
import TopicsMain from "./modules/workshops/topics/TopicsMain";
import Quiz from "./modules/quiz/Quiz";

import { ProfileProvider } from "./contexts/ProfileContext";
import Venue from "./modules/workshops/venue/Venue";
/*
https://v1.tailwindcss.com/
https://css-tricks.com/style-form-tailwind-css/
https://medium.com/@jwngr/implementing-firebase-auth-session-durations-82fa7b1fff08
https://medium.com/@ryanchenkie_40935/react-authentication-how-to-store-jwt-in-a-cookie-346519310e81
*/

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      displayName: null,
      userID: null,
      idToken: null,
      isLoading: false,
      //sessionTimeout: null,
    };
    this.sessionTimeout = null;

    this.signOut = this.signOut.bind(this);
    this.clearState = this.clearState.bind(this);

    console.log("Constructor in APP", localStorage.getItem("uid"));
    if (firebase.auth().currentUser !== null) {
      console.log("Firebase Check");
      this.state.user = auth.currentUser;
      this.state.displayName = auth.currentUser.displayName;
      this.state.userID = auth.currentUser.uid;
      this.state.idToken = localStorage.getItem("idToken");
    }
  }

  clearState() {
    this.setState({
      user: null,
      displayName: null,
      userID: null,
      idToken: null,
      isLoading: false,
      //sessionTimeout: null,
    });
    localStorage.removeItem("idToken");
    localStorage.removeItem("uid");
    localStorage.removeItem("displayName");
  }

  signOut() {
    toast(`Please login again to access the services!!!`);
    this.sessionTimeout && clearTimeout(this.sessionTimeout);
    auth.signOut().then(() => {});
  }

  componentDidMount() {
    // console.log("Component Did Mount App.js");
    if (this.state && this.state.user) {
    }
    auth.onAuthStateChanged((FBUser) => {
      if (FBUser) {
        this.setState({
          user: FBUser,
          displayName: FBUser.displayName,
          userID: FBUser.uid,
          isLoading: false,
          fbUser: FBUser,
        });

        FBUser.getIdTokenResult(true)
          .then((idTokenResult) => {
            let idToken = idTokenResult.token;
            this.setState({
              ...this.state,
              idToken: idToken,
            });

            // getProfile(FBUser.uid);
            localStorage.setItem("idToken", idToken);
            localStorage.setItem("uid", FBUser.uid);
            localStorage.setItem("displayName", FBUser.displayName);

            const authTime = idTokenResult.claims.auth_time * 1000;
            let sessionDuration = 1000 * 60 * 60 * 8 * 1; // 8 hours
            // sessionDuration = 1000 * 60 * 1 * 1 * 1; // 1 minute
            const millisecondsUntilExpiration =
              sessionDuration - (Date.now() - authTime);
            this.sessionTimeout = setTimeout(
              () => this.signOut(),
              millisecondsUntilExpiration
            );
          })
          .catch((error) => {
            // console.log("Failed to get idToken");
            toast.error(
              `User authentication issued errors, some of the features may not work, ${error.message}`
            );
            this.clearState();
          });
      } else {
        // User logged out
        console.log("Something Wrong");
        this.props.history.push("/");
        this.clearState();
        // clearState();
      }
    });
  }

  componentWillUnmount() {
    console.log("Component will mount in APP", this.state);
  }

  getNoAuthHome() {
    return (
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/login">
          <Login user={this.state.displayName} />
        </Route>
      </Switch>
    );
  }

  getAuthHome() {
    return (
      <ProfileProvider profile={this.state.user}>
        <div className="bg-image">
          {this.state.user && (
            // <Navigation
            //   user={this.state.displayName}
            //   logoutUser={this.logoutUser}
            //   addWorkshop={this.addWorkshop}
            // />

            <Nav
              user={this.state.displayName}
              userId={this.state.userID}
              logoutUser={(e) => {
                e.preventDefault();
                this.signOut();
              }}
            />
          )}

          <Switch>
            <Route path="/" exact>
              <Home
                user={this.state.displayName}
                userId={this.state.userID}
                idToken={this.state.idToken}
                isLoading={this.state.isLoading}
                logoutUser={(e) => {
                  e.preventDefault();
                  this.signOut();
                }}
              />
            </Route>
            {/* <Route path="/login">
              <Login user={this.state.displayName} />
            </Route> */}
            <Route path="/register">
              <Register />
            </Route>

            <Route path="/workshops/:userId">
              <Workshops
                // user={this.state.displayName}
                userId={this.state.userID}
              />
            </Route>
            <Route path="/participants/:workshopID">
              <Participants
                adminUser={this.state.userID}
                addWorkshop={this.addWorkshop}
                workshops={this.state.workshops}
              />
            </Route>

            <Route path="/checkin/:workshopID">
              <CheckIn />
            </Route>
            <Route path="/workshop/:workshopID">
              <Workshop />
            </Route>

            <Route path="/virtual-venue/:workshopId/check-in/:checkInId">
              <Venue />
            </Route>

            <Route path="/appointments">
              <Appointments
                user={this.state.displayName}
                addWorkshop={this.addWorkshop}
                workshops={this.state.workshops}
              />
            </Route>
            <Route path="/schedule-workshop/:workshopId">
              <AddAppointment />
            </Route>
            <Route path="/user/:userId">
              <Profile />
            </Route>

            <Route path="/create">
              <Create />
            </Route>
            <Route path="/collaborate">
              <Collaborate />
            </Route>
            <Route path="/communicate">
              <Communicate />
            </Route>
            <Route path="/discuss">
              <Discuss />
            </Route>
            <Route path="/notes">
              <Notes />
            </Route>
            <Route path="/poll">
              <Quiz />
            </Route>
            <Route path="/topics">
              <TopicsMain />
            </Route>
          </Switch>

          <Welcome userName={this.state.displayName} />
          <ToastContainer
            position={"top-center"}
            autoClose={5000}
            transition={Slide}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            progress={0}
          />
        </div>
      </ProfileProvider>
    );
  }

  render() {
    return this.state.user === null ? this.getNoAuthHome() : this.getAuthHome();
  }
}

export default withRouter(App);
