import { FcMindMap } from "react-icons/fc";

const TopicsMain = ({ user }) => {
  let grid = "tw-my-2 tw-px-2 tw-w-1/2 tw-text-center";

  return (
    <div className="container">
      <div className="tw-mt-20">
        {user == null && (
          <div className="tw-m-20 tw-text-8xl tw-text-gray-500 tw-text-center tw-font-semibold">
            Topics
          </div>
        )}
        <div className="tw-flex tw-flex-wrap tw-text-4xl tw-justify-center ">
          <div className={grid + " "}>
            {/* <!-- Column Content --> */}
            <div className="tw-m-3 tw-bg-pink-400 tw-py-10 tw-rounded-xl tw-shadow-lg">
              <FcMindMap className="tw-text-left tw-text-5xl tw-mb-4" />
              Topics
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicsMain;
