const Input = ({ label, name, type, value, onChange, index }) => {
  return (
    <div className="tw-tw-sm:tw-grid tw-sm:tw-grid-cols-3 tw-sm:tw-gap-4 tw-sm:tw-items-start  tw-sm:tw-pt-5">
      <label
        htmlFor={name}
        className="tw-block tw-py-3 tw-px-3 tw-text-xs tw-uppercase tw-font-semibold tw-sm:mt-px tw-sm:tw-pt-2"
      >
        {label}
      </label>
      <div className="tw-mt-1 tw-sm:mt-0 tw-sm:tw-col-span-2">
        <input
          type={type}
          name={name}
          id={name}
          onChange={(e) => {
            e.preventDefault();
            onChange(e.target.value, index);
          }}
          value={value}
          className="tw-shadow-sm tw-py-3 tw-px-3 tw-text-grey-darkest tw-focus:ring-indigo-500 tw-focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full tw-sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
        />
      </div>
    </div>
  );
};

export default Input;
